
import { useState } from "react";
import { BreadCrumbBG01, BreadCrumbIMG02 } from "../../assets";

import Footer from "../../components/common/Footer";

import Header from "../../components/common/Header";
import Wrapper from "../home/wrapper/Wrapper";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { AuthService } from "../../api";
import { toast } from "react-toastify";





export default function RegisterPage() {
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [referenceCode, setReferenceCode] = useState<string | undefined>(undefined)

  const dispatch = useDispatch()
  const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

  const handleRegister = async () => {
    if(email.length === 0) {
      toast("Email Cannot Be Empty!")
      return
    }

    if(password.length === 0) {
      toast("Password Cannot Be Empty!")
      return
    }

    setIsWorking(true)
    AuthService.register(email, password, referenceCode)    
    .then((r) => {
      setIsWorking(false)
      toast("Confirmation Link Sent To Your Mail!") 
    })
    .catch((e) => {      
      setIsWorking(false)      
      toast("Confirmation Link Sent To Your Mail!")      
    })        
    
    setPassword("")
    setEmail("")
    
  }

  return (
    <Wrapper>
      <Header />
      <main className="main--area">
        <section
          className="breadcrumb-area"
          style={{ backgroundImage: `url(${BreadCrumbBG01})` }}
        >
          <div className="container">
            <div className="breadcrumb__wrapper">
              <div className="row">
                <div className="col-xl-6 col-lg-7">
                  <div className="breadcrumb__content">
                    <h2 className="title mb-5">Register</h2>
                    <nav aria-label="breadcrumb">
                      <form action="#" className="footer-newsletter-form">
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.currentTarget.value)}
                          
                          type="email"
                          placeholder="E-Mail"
                          className="mb-3"
                        />
                        <input
                          value={password}
                          onChange={(e) => setPassword(e.currentTarget.value)}                          
                          type="password"
                          placeholder="Password"
                        />
                         <input
                          value={referenceCode}
                          onChange={(e) => {
                            if(e.currentTarget.value.length === 0) {
                              setReferenceCode(undefined)
                            } else {
                              setReferenceCode(e.currentTarget.value)
                            }
                            
                          }}                          
                          type="text"
                          placeholder="Reference Code (Optional)"
                          className="my-reference-code"
                        />
                      </form>
                      <div className="flex-column d-flex mt-5">
                        <button
                          onClick={() => handleRegister()}
                          
                          className="btn mt-2"
                        >
                          Register
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 position-relative d-none d-lg-block">
                  <div className="breadcrumb__img">
                    <img
                      src={BreadCrumbIMG02}
                      alt="img"
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </Wrapper>
  );
}
