import { KONG_URL } from "../../../constants/constants";

const AuthRoutes = {
    baseUrl : KONG_URL, 
    auth : "/auth",
    changePassword : "/change-password",
    login : "/login",
    register : "/register",
    sendConfirmation : "/send-confirmation",
    confirm : "/confirm"
}

export default AuthRoutes