import React from 'react'
import './navbar.css'
import { ConnectWalletIcon, HamburgerMenuIcon, TransactionIcon, UserIcon } from '../../../assets'
import { useScreenSize } from '../../../utils'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { useDispatch, useSelector } from 'react-redux'
import { PaneEnums } from '../../../enums'
import { useSDK } from '@metamask/sdk-react'
import { toast } from 'react-toastify'
import { State } from '../../../state/reducers'
import WalletState from '../../../state/reducers/state-types/wallet'
import UserState from '../../../state/reducers/state-types/user'

function Navbar() {

  const screenSize = useScreenSize()
  const dispatch = useDispatch()
  const {changePane, setIsSidebarOpen} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
  const {setWallet} = bindActionCreators(actionCreators.WalletActionCreators, dispatch)
  const walletState = useSelector((s: State) => s.wallet as WalletState)
  const userState = useSelector((s: State) => s.user as UserState)

  const { sdk } = useSDK();

  const connect = async () => {
      try {
          const accounts = await sdk?.connect();         
          setWallet((accounts as string[])[0])                    
      } catch (err) {
          toast("Error While Connecting Wallet!")
      }
  };

  if(screenSize.width < 1024) {
    return (
      <nav id='mobile-navbar-container'>
          <div id='mobile-nav-links'>
            <button
              onClick={() => setIsSidebarOpen(true)}
            >
              <img src={HamburgerMenuIcon} alt='hamburger-menu'/>
            </button>
            <button
                onClick={() => {
                  connect()
                }}
            >
              {
                walletState.wallet 
                ?
                <span>{ walletState.wallet?.substring(0,12)}</span>
                :
                <img src={ConnectWalletIcon} alt='connect-wallet'/>
              }
              
              
            </button>
            <button
              onClick={() => changePane(PaneEnums.Panes.TRANSACTIONS)}
            >
              <img src={TransactionIcon} alt='transaction'/>
            </button>
          </div>
          <button id='mobile-nav-user' onClick={() => changePane(PaneEnums.Panes.PROFILE)}>
            <img src={UserIcon} alt='user'/>
            <span>{userState.me?.email}</span>
          </button>
      </nav>
    )
  }

  return (
    <div id='desktop-navbar-container'>
      <nav>
        <div id='desktop-nav-links'>
          <button 
            className='desktop-nav-link-container'
            onClick={() => changePane(PaneEnums.Panes.TRANSACTIONS)}
          >
            <img src={TransactionIcon} alt='transaction'/>
            <span>Transactions</span>
          </button>
          <button className='desktop-nav-link-container'
            onClick={() => {
              connect()
            }}
          >
            <img src={ConnectWalletIcon} alt='connect-wallet'/>
            <span>{walletState.wallet ? walletState.wallet?.substring(0,12) : "Connect Wallet"}</span>
          </button>
        </div>
        <button id='desktop-nav-user' onClick={() => changePane(PaneEnums.Panes.PROFILE)}>
          <img src={UserIcon} alt='user'/>
          <span>{userState.me?.email}</span>
        </button>
      </nav>
    </div>
  )
}

export default Navbar