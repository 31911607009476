import React, { useEffect, useRef } from 'react'
import { PaneEnums } from '../../enums'
import Dashboard from './dashboard/Dashboard'
import Inventory from './inventory/Inventory'
import Marketplace from './marketplace/Marketplace'
import ManageTeams from './manage-teams/ManageTeams'
import Payment from './payment/Payment'
import BuyDucky from './buyducky/BuyDucky'
import Breeding from './breeding/Breeding'
import Profile from './profile/Profile'
import Transactions from './transactions/Transactions'
import Settings from './settings/Settings'
import Explore from './explore/Explore'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../state'
import { State } from '../../state/reducers'
import { GameService, MarketplaceService, UserService, VaultService } from '../../api'
import { useScreenSize } from '../../utils'
import { BottomNavbar, MobileSidebar, Navbar, Sidebar } from '../../components'


const selectScreen = (screen: string, forMobile: boolean) : React.JSX.Element => {
    
  switch(screen) {
    case PaneEnums.Panes.DASHBOARD:
      return <Dashboard/>
    case PaneEnums.Panes.INVENTORY:
      return <Inventory />
    case PaneEnums.Panes.MARKETPLACE:
      return <Marketplace />
    case PaneEnums.Panes.MANAGE_TEAMS:
      return <ManageTeams  />
    case PaneEnums.Panes.PAYMENT:
      return <Payment isEmpty={false} forMobile={forMobile}/>
    case PaneEnums.Panes.BUYDUCKY:
      return <BuyDucky />
    case PaneEnums.Panes.BREEDING:
      return <Breeding/>
    case PaneEnums.Panes.PROFILE:
      return <Profile isEmpty={false} forMobile={forMobile}/>
    case PaneEnums.Panes.TRANSACTIONS:
        return <Transactions isEmpty={false} forMobile={forMobile}/>
    case PaneEnums.Panes.SETTINGS:
        return <Settings/>
    case PaneEnums.Panes.EXPLORE:
      return <Explore/>
    default:
      return <Dashboard/>
  }
}



function Panel() {

  const dispatch = useDispatch()  
  const {setMarketplace} = bindActionCreators(actionCreators.MarketplaceActionCreators, dispatch)
  const {setMe, setInventory, setTeams} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
  const {setDashboard, setLootable, setHistory} = bindActionCreators(actionCreators.GameActionCreators, dispatch)
  const {setVault} = bindActionCreators(actionCreators.VaultActionCreators, dispatch)
  const uiState = useSelector((s: State) => s.ui)
  const timerRef = useRef<NodeJS.Timer | undefined>(undefined);

  useEffect(() => {
    
    UserService.getMe()
    .then((data: any) => {      
      
      if(!data.transactions) {
        data.transactions = []
      }
      
      setMe(data)
    })

    UserService.getInventory(0)
    .then((data: any) => {
      if(data) {
        setInventory(data)
      }

      
    })

    UserService.getTeams(0)
    .then((data: any) => {
      if(data) {
        setTeams(data)
      }
      
    })
    
    MarketplaceService.getMarketplace(0)
      .then((data) => {        
        if(data) {
          setMarketplace(data)
        }
        
      })      

      GameService.getDashboard()
      .then((data) => {
        if(data) {
          setDashboard(data)
        }
      })

      GameService.getHistory()
      .then((data) => {        
        if(data) {                    
          setHistory(data)
        }
      })

      GameService.getLootable()
      .then((data) => {
        if(data) {          
          setLootable(data)
        }
      })

      VaultService.getVaultByCoinName('ducky')
      .then((data) => {
        setVault(data)
      })      
  }, [])


  
 

  const screenSize = useScreenSize();

  
  if (screenSize.width < 1024) {
    return (
      <>
         <div id='mobile-layout'>
        <Navbar />
        <div id='mobile-content'>
          
          {selectScreen(uiState.currentPane, true)}
          
        </div>
        <BottomNavbar />
      </div>
      <MobileSidebar/>
      </>
     
    )
  }

  return (
    <div id='desktop-layout'>
      <Sidebar />
      <div id='desktop-layout-rightcol'>
        <Navbar />
        <div id='desktop-content-wrapper'>
          <div id='desktop-content'>
            {selectScreen(uiState.currentPane, false)}
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel