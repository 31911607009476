import React from "react";



import { Logo,  SocialIcon01, SocialIcon02, SocialIcon03, SocialIcon04 } from "../../assets";

const Footer = () => {
  return (
    <footer className="footer-style-one">
      <div className="footer__top-wrap">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-xl-4 col-lg-5 col-md-7">
              <div className="footer-widget">
                <div className="footer-logo logo">
                  <a href="/contact">
                    <img src={Logo} alt="Logo" width={177} height={40} />
                  </a>
                </div>
                <div className="footer-text">
                  <p className="desc">
                  Duckyvucky is a new generation browser-based game that laid its first foundations in the year 2021, revolutionizing the Play-to-Earn (P2E) concept.                  </p>
                  <p className="social-title">
                    Active{" "}
                    <span>
                      With Us <i className="fas fa-angle-double-right"></i>
                    </span>
                  </p>
                  <div className="footer-social">
                    <a href="#">
                      <img src={SocialIcon01} alt="iocn" width={30} height={30} />
                    </a>
                    <a href="#">
                      <img src={SocialIcon02} alt="iocn" width={30} height={30} />
                    </a>
                    <a href="#">
                      <img src={SocialIcon03} alt="iocn" width={30} height={30} />
                    </a>
                    <a href="#">
                      <img src={SocialIcon04} alt="iocn" width={30} height={30} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-5 col-sm-6">
              <div className="footer-widget widget_nav_menu">
                <h4 className="fw-title">Quick Link</h4>
                <ul className="list-wrap menu">
                  <li>
                    <a href="#">Gaming</a>
                  </li>
                   <li>
                    <a href="#">Social Network</a>
                  </li>
                 
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-5 col-sm-6">
              <div className="footer-widget widget_nav_menu">
                <h4 className="fw-title">Supports</h4>
                <ul className="list-wrap menu">
                  <li>
                    <a href="#">Setting & Privacy</a>
                  </li>
                  <li>
                    <a href="#">Help & Support</a>
                  </li>
                  <li>
                    <a href="#">24/7 Supports</a>
                  </li>
                  <li>
                    <a href="#">Our News</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-xl-4 col-lg-5 col-md-7">
              <div className="footer-widget">
                <h4 className="fw-title">Newsletter</h4>
                <div className="footer-newsletter">
                  <p>Subscribe our newsletter to get our latest update & newsconsectetur</p>
                  <form action="#" className="footer-newsletter-form">
                    <input type="email" placeholder="Your email address" />
                    <button type="submit"><i className="flaticon-paper-plane"></i></button>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="copyright__wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="copyright__text">
                <p>
                  Copyright © {new Date().getFullYear()} - All Rights Reserved
                  By <span>Duck Agency</span>
                </p>
              </div>
            </div>
        {/*   <div className="col-md-5">
              <div className="copyright__card text-center text-md-end">
                <Image src={payment} alt="img" />
              </div>
          </div>  */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
