import React from 'react'
import './profile-card.css'
import { TetherUsdtIcon } from '../../assets';
import { useSelector } from 'react-redux';
import { State } from '../../state/reducers';
import VaultState from '../../state/reducers/state-types/vault';

type Props = {    
    usdtBallance: number,    
    ducky: number,
    referenceCode: string
}

function ProfileCard(props: Props) {
    
    const vaultState = useSelector((s:State) => s.vault as VaultState)

    return (
        <div className='profile-card-container'>
            <div className='profile-card-col'
                style={{
                    marginLeft: 16
                }}
            >
                <div>
                    <img src={TetherUsdtIcon} alt='tether'/>
                    <span>USDT Balance: {props.usdtBallance}</span>
                </div>
                <div>                    
                    <span>$DUCKY Balance: {props.ducky}</span>
                </div>
                <div>                    
                    <span>Total Balance (USDT): {(props.ducky * (vaultState.vault?.vaultValue!/vaultState.vault?.coinSupply!)) + props.usdtBallance}</span>
                </div>
            </div>

            <div className='profile-card-col profile-card-col-second'>
                <div>                    
                    <span>Reference Code: <span>{props.referenceCode}</span></span>
                </div>                
            </div>
            
        </div>
    )
}

export default ProfileCard