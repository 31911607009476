import React from 'react'
import './sidebar.css'
import { BreedingIcon, BuyduckyIcon, DashboardIcon, ExploreIcon, InventoryIcon, Logo, LogoutIcon, ManageTeamsIcon, MarketplaceIcon, PaymentIcon, SettingsIcon } from '../../../assets'
import SidebarButton from '../../reusable/sidebar-button/SidebarButton'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { State } from '../../../state/reducers'
import { PaneEnums } from '../../../enums'

function Sidebar() {
  const dispatch = useDispatch()
  const {changePane} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
  const uiState = useSelector((s: State) => s.ui)
  
  return (
    <div id='desktop-sidebar-container'>
      <img className='logo' src={Logo} alt='Logo'/>
      <nav>
        <SidebarButton icon={DashboardIcon} text='DASHBOARD' isSelected={uiState.currentPane === PaneEnums.Panes.DASHBOARD} onClick={changePane} data={PaneEnums.Panes.DASHBOARD}/>
        <SidebarButton icon={InventoryIcon} text='INVENTORY' isSelected={uiState.currentPane === PaneEnums.Panes.INVENTORY} onClick={changePane} data={PaneEnums.Panes.INVENTORY}/>
        <SidebarButton icon={MarketplaceIcon} text='MARKETPLACE' isSelected={uiState.currentPane === PaneEnums.Panes.MARKETPLACE} onClick={changePane} data={PaneEnums.Panes.MARKETPLACE}/>
        <SidebarButton icon={ManageTeamsIcon} text='MANAGE TEAMS' isSelected={uiState.currentPane === PaneEnums.Panes.MANAGE_TEAMS} onClick={changePane} data={PaneEnums.Panes.MANAGE_TEAMS}/>
        <SidebarButton icon={ExploreIcon} text='EXPLORE' isSelected={uiState.currentPane === PaneEnums.Panes.EXPLORE} onClick={changePane} data={PaneEnums.Panes.EXPLORE}/>
        <SidebarButton icon={PaymentIcon} text='PAYMENT' isSelected={uiState.currentPane === PaneEnums.Panes.PAYMENT} onClick={changePane} data={PaneEnums.Panes.PAYMENT}/>
        <SidebarButton icon={BuyduckyIcon} text='BUYDUCKY' isSelected={uiState.currentPane === PaneEnums.Panes.BUYDUCKY} onClick={changePane} data={PaneEnums.Panes.BUYDUCKY}/>
        <SidebarButton icon={BreedingIcon} text='BREEDING' isSelected={uiState.currentPane === PaneEnums.Panes.BREEDING} onClick={changePane} data={PaneEnums.Panes.BREEDING}/>
        <SidebarButton icon={SettingsIcon} text='SETTINGS' isSelected={uiState.currentPane === PaneEnums.Panes.SETTINGS} onClick={changePane} data={PaneEnums.Panes.SETTINGS}/>
      </nav>
      <button 
        className='desktop-sidebar-logout'
        onClick={() => {
          localStorage.removeItem("token")
          window.location.reload()
        }}
      >
        <img src={LogoutIcon} alt='logout'/> 
        LOGOUT
      </button>
    </div>
  )
}

export default Sidebar