import React from 'react'
import './loading-screen.css'
import { ItemDuckLegendary } from '../../../assets'
import { useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import UIState from '../../../state/reducers/state-types/ui'

function LoadingScreen() {
  const uiState = useSelector((s: State) => s.ui as UIState)

  return (
    <div 
      className='loader-background'
      style={{
        visibility: uiState.isWorking ? "visible" : "hidden",
        display: uiState.isWorking ? "flex" : "none"
      }}
    >
        <span className="loader">
            <img src={ItemDuckLegendary} alt='duck'/>
        </span>
    </div>
  )
}

export default LoadingScreen