import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import HeroBanner from "../../components/hero/HeroBanner";
import NftItemArea from "../../components/nft-item/NftItemArea";
import RoadMapArea from "../../components/road-map/RoadMapArea";
import VideoArea from "../../components/video-area/VideoArea";
import Wrapper from "./wrapper/Wrapper";

export default function HomePage() {
    return (
        <Wrapper>
        <Header />
        <main className="main--area">
          <HeroBanner />
          <NftItemArea />
          <VideoArea />
          <RoadMapArea />
        </main>
        <Footer/>
      </Wrapper>
    );
  }