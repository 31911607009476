import React from 'react'
import './transactions.css'
import { useScreenSize } from '../../../utils'
import { TransactionCard } from '../../../components'
import { useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import UserState from '../../../state/reducers/state-types/user'



type Props = {
    isEmpty: boolean,
    forMobile: boolean
}

function Transactions(props: Props) {
    const screenSize = useScreenSize();
   
    const userState = useSelector((s: State) => s.user as UserState)
    

    return (
        <section
            id='transactions'
            style={{
                flexDirection: props.isEmpty ? 'row' : 'column',
                justifyContent: props.isEmpty ? 'space-between' : 'normal',
                alignItems: 'center'

            }}
        >
            {
                screenSize.width > 1024 ?
                    <div id='transactions'>
                        <div id='transactions-header'>
                           <span>Transactions</span>
                            
                        </div>
                        {userState.me ? userState.me.transactions.filter((t) => t.confirmed).map((t) => (
                            <TransactionCard key={t.id} from={t.from} to={t.to} type={t.type.toUpperCase()} given={t.amount.toString()} received={(t.amount * t.rate).toString()} date={t.createdAt.toString()} />
                        )) : null}
                        
                      

                    </div>
                    :
                    <>
                         {userState.me ? userState.me.transactions.filter((t) => t.confirmed).map((t) => (
                            <TransactionCard key={t.id} from={t.from} to={t.to} type={t.type.toUpperCase()} given={t.amount.toString()} received={(t.amount * t.rate).toString()} date={t.createdAt.toString()} />
                        )) : null}

                    </>
            }

        </section>
    )
}

//transactions-header-other-container-type-container

export default Transactions