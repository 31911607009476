import { UIActionType } from "../action-types"
import UIState  from "./state-types/ui"

const initalState: UIState = {
    currentPane: "DASHBOARD",
    isWorking: false,
    isSidebarOpen: false
}


interface Action {
    type: string,
    payload: any
}


const reducer = (state: any = initalState, action: Action) => {
    switch(action.type) {
        case UIActionType.CHANGE_PANE:
            return {...state, currentPane: action.payload}
        case UIActionType.SET_IS_WORKING:
            return {...state, isWorking: action.payload}
        case UIActionType.SET_IS_SIDEBAR_OPEN:
            return {...state, isSidebarOpen: action.payload}
        default: 
            return state            
    }
}

export default reducer