import { MarketplaceActionType } from "../action-types"
import MarketplaceState from "./state-types/marketplace"


const initalState: MarketplaceState = {
    marketplace: []
}


interface Action {
    type: string,
    payload: any
}


const reducer = (state: any = initalState, action: Action) => {
    switch(action.type) {
        case MarketplaceActionType.SET_MARKETPLACE:
            return {...state, marketplace: action.payload}
        default: 
            return state            
    }
}

export default reducer