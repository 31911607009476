import { Outlet, Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const ProtectedRoutes = () => {
    let token = localStorage.getItem("token")
    var splittedHref = window.location.href.split("/")
    var currentLocation = splittedHref[splittedHref.length - 1]

    // Current date in UTC
    var currentDateUTC = new Date();

    // Get the current UTC time and adjust it for British time zone (UTC+1 with no daylight saving time)
    var currentBritishTime = new Date(currentDateUTC.getTime() + (60 * 60 * 1000));

    // Expected date (replace this with your expected date) in British time
    var expectedDate = new Date('2024-05-07T00:00:00+01:00');

    // Check if current date is smaller than expected date
    if (currentBritishTime < expectedDate) {
        
        if(currentLocation === "login" || currentLocation === "register") {
            if(currentLocation === "login") {
                toast("You Can Login At 7'th Of May")
            }
            
            return <Outlet/>
        } else {
            toast("You Can Login At 7'th Of May")
            return <Navigate to="/"/>
        }
        
        
    } 

    if((currentLocation === "login" || currentLocation === "register") && token) {
        return <Navigate to="/panel"/>
    } else if ((currentLocation === "login" || currentLocation === "register") && !token) {
        return <Outlet/>
    }
    
    
    return(
        token  ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default ProtectedRoutes