import React, { useEffect, useState } from 'react'
import './breeding.css'
import { BreedModal, Modal} from '../../../components'
import {ItemDuckFemale, ItemDuckLegendary, ItemDuckMale, SwardIcon } from '../../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import UserState from '../../../state/reducers/state-types/user'
import { Duck } from '../../../data'
import { toast } from 'react-toastify'
import { ItemService, UserService } from '../../../api'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import GameState from '../../../state/reducers/state-types/game'


function Breeding() {
    
    const userState = useSelector((s: State) => s.user as UserState)
    const [isBreedModalOpen, setIsBreedModalOpen] = useState(false)
    const [maleDuck, setMaleDuck] = useState<Duck | undefined>(undefined)
    const [femaleDuck, setFemaleDuck] = useState<Duck | undefined>(undefined)
    const [availableDucks, setAvailableDucks] = useState<Duck[]>([])

    const dispatch = useDispatch()
    const {setInventory} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
    const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
    const gameState = useSelector((s: State) => s.game as GameState)
    
    

    const openBreedModal = (duck: Duck) => {
        if(duck.duckType === "legendary") {
            toast("Legendary Ducks Cant Be Breeded")
            return
        }

        if( duck.duckType === "female") {
            setFemaleDuck(duck)
        } else {
            setMaleDuck(duck)
        }

        const duckInventories = userState.inventory.filter((i) => i.item.itemType === "duck")
        const ducks = duckInventories.map((i) => i.item as Duck)


        let allDucks: Duck[] = []
                                      
        for(const game of gameState.dashboard) {
        if(userState.me?.id === game.miner.id) {
            allDucks = [...allDucks, ...game.minerTeam.ducks]
        } else {
            allDucks = [...allDucks, ...game.looterTeam!.ducks]
        }
        }

        const allDuckIds = allDucks.map((duck) => duck._id)

  


        if(duck.duckType === "male"){
            const availableDucks = ducks.filter((d) => (d.duckType === "female") && (d.breedCount === duck.breedCount) && !allDuckIds.includes(d._id))  
            setAvailableDucks(availableDucks)
        } else {
            const availableDucks = ducks.filter((d) => (d.duckType === "male") && (d.breedCount === duck.breedCount) && !allDuckIds.includes(d._id))  
            setAvailableDucks(availableDucks)
        }
        
        

        setIsBreedModalOpen(true)
    }

    const selectDuck = (duck: Duck) => {                
        if(duck.duckType === "legendary") {
            toast("Legendary Ducks Cant Be Breeded")
            return
        }

        if(duck.duckType === "female") {
            setFemaleDuck(duck)
        } else {
            setMaleDuck(duck)
        }
    }

    const breed = (legendaryBoost: boolean) => {
        if(maleDuck === undefined) {
            toast("Male Duck Needed For Breeding!")
            return
        }

        if(femaleDuck === undefined) {
            toast("Female Duck Needed For Breeding!")
            return
        }

        if(maleDuck.breedCount !== femaleDuck.breedCount) {
            toast("Breed Counts Have To Be Same!")
            return
        }

        setIsWorking(true)
        

        ItemService.breed(maleDuck._id, femaleDuck._id, legendaryBoost)        
        .then((r) => {
            UserService.getInventory(0)
                .then((data) => {
                    if(data) {
                        setInventory(data)
                      }                    
                    setIsWorking(false)
                })
                .catch((e) => {
                    setIsWorking(false)
                })
        })        
        .catch((e) => {
            setIsWorking(false)
        })
        setIsBreedModalOpen(false)
    }



    useEffect(() => {
        if(!isBreedModalOpen) {            
            setFemaleDuck(undefined)
            setMaleDuck(undefined)
        }
    }, [isBreedModalOpen])


    return (
        <>
        <section id='breeding'>
            <div id='breeding-header'>  
                <span className='breeding-title'>Breeding</span>         
               
            </div>
            <div id='breeding-ducks'>
                {userState.inventory.filter((i) => i.item.itemType === "duck").map((i) => {
                            let d = i.item as Duck
                            return (
                                <div className='breeding-duck-card' key={d._id}>
                                <img alt='duck'  className='breeding-duck-img' src={d.duckType === "male" ? ItemDuckMale : (d.duckType === "female" ? ItemDuckFemale : ItemDuckLegendary)}/>
                                <div className='breeding-duck-card-info'>
                                    <span className='breed-count'>Breed Count: {d.breedCount}</span>
                                    <div className='breeding-duck-card-info-row'>
                                        <span>Lv.{d.level}</span>
                                        <div className='breeding-duck-power'>
                                            <img  alt='sword' src={SwardIcon}/>
                                            <span>{d.power}</span>
                                        </div>
                                        <div className='breeding-duck-number' style={{color: "#000"}}>                                
                                            #{d.number}
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={() => {
                                      if(d.breedCount >= 3) {
                                          toast("Breed count limited to 3!")
                                          return
                                      }

                                      let allDucks: Duck[] = []
                                      
                                      for(const game of gameState.dashboard) {
                                        if(userState.me?.id === game.miner.id) {
                                            allDucks = [...allDucks, ...game.minerTeam.ducks]
                                        } else {
                                            allDucks = [...allDucks, ...game.looterTeam!.ducks]
                                        }
                                      }

                                      const allDuckIds = allDucks.map((duck) => duck._id)

                                      if(allDuckIds.includes(d._id)) {
                                        toast("Duck is in a game!")
                                        return
                                      }

          
                                        openBreedModal(d)
                                    }}
                                >
                                    SELECT
                                </button>
                            </div>
                            )
                  
                        })}
              
            </div>
        </section>
        <Modal
            isOpen={isBreedModalOpen}
            overlayClickCallback={setIsBreedModalOpen}
        >
            <BreedModal setIsBreedModalOpen={setIsBreedModalOpen} breed={breed} ducks={availableDucks}  maleDuck={maleDuck} femaleDuck={femaleDuck} selectDuck={selectDuck}/>
        </Modal>
        </>
      
    )
}

export default Breeding