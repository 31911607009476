import React, { useState } from 'react'
import './sort-select.css'
import { ArrowDownIcon, ArrowUpIcon } from '../../../assets'
import { MarketplaceService } from '../../../api'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'

type Props = {
    type: string
}

function SortSelect(props: Props) {
    const [isOpen, setIsOpen] = useState(false)
    const [isHighToLow, setIsHighToLow] = useState<boolean | undefined>(undefined) 

    const dispatch = useDispatch()
    const {setMarketplace} = bindActionCreators(actionCreators.MarketplaceActionCreators, dispatch)
    const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

  return (
   <button 
    className='sort-select-wrapper'
    onClick={(e) => {
        setIsOpen(!isOpen)
        e.stopPropagation()
    }}
    >
    {props.type}
    {
        isHighToLow === undefined
        ?
        null
        :
        (
            isHighToLow 
            ?
            <img src={ArrowDownIcon}/> 
            :
            <img src={ArrowUpIcon}/> 
        )
    }
    {
        isOpen ? 
        <div
        style={{
            display: isOpen ? "flex" : "none",
            visibility: isOpen ? "visible" : 'hidden',
            flexDirection: "column",
            gap: 10
        }}
    >
        <button
            onClick={() => {
                setIsHighToLow(undefined)
                setIsOpen(false)
                setIsWorking(true)

                switch(props.type) {
                    case "Price":
                        MarketplaceService.getMarketplace(0, undefined, undefined, undefined)
                        .then((data: any) => {                            
                            
                            if(data) {
                                setMarketplace(data)
                            }
                            setIsWorking(false)
                        })
                        .catch((e) => {
                            
                            setIsWorking(false)
                        })
                        break;
                    case "Date":
                        MarketplaceService.getMarketplace(0, undefined, undefined)
                        .then((data: any) => {                            
                            
                            if(data) {
                                setMarketplace(data)
                            }
                            setIsWorking(false)
                        })
                        .catch((e) => {
                            
                            setIsWorking(false)
                        })
                        break;
                    case "Level":
                        MarketplaceService.getMarketplace(0, undefined, undefined, undefined, undefined)
                        .then((data: any) => {                            
                            
                            if(data) {
                                setMarketplace(data)
                            }
                            setIsWorking(false)
                        })
                        .catch((e) => {
                            
                            setIsWorking(false)
                        })
                        break;
                }
            }}
        >
            Clear
        </button>
        <button
             onClick={() => {
                setIsHighToLow(true)                
                setIsOpen(false)
                switch(props.type) {
                    case "Price":
                        MarketplaceService.getMarketplace(0, undefined, undefined, "htl")
                        .then((data: any) => {                            
                            
                            if(data) {
                                setMarketplace(data)
                            }
                            setIsWorking(false)
                        })
                        .catch((e) => {
                            
                            setIsWorking(false)
                        })
                        break;
                    case "Date":
                        MarketplaceService.getMarketplace(0, undefined, "htl")
                        .then((data: any) => {                            
                            
                            if(data) {
                                setMarketplace(data)
                            }
                            setIsWorking(false)
                        })
                        .catch((e) => {
                            
                            setIsWorking(false)
                        })
                        break;
                    case "Level":
                        MarketplaceService.getMarketplace(0, undefined, undefined, undefined, "htl")
                        .then((data: any) => {                            
                            
                            if(data) {
                                setMarketplace(data)
                            }
                            setIsWorking(false)
                        })
                        .catch((e) => {
                            
                            setIsWorking(false)
                        })
                        break;
                }
            }}
        >
            High To Low
        </button>
        <button
             onClick={() => {
                setIsHighToLow(false)
                setIsOpen(false)

                switch(props.type) {
                    case "Price":
                        MarketplaceService.getMarketplace(0, undefined, undefined, "lth")
                        .then((data: any) => {                            
                            
                            if(data) {
                                setMarketplace(data)
                            }
                            setIsWorking(false)
                        })
                        .catch((e) => {
                            
                            setIsWorking(false)
                        })
                        break;
                    case "Date":
                        MarketplaceService.getMarketplace(0, undefined, "lth")
                        .then((data: any) => {                            
                            
                            if(data) {
                                setMarketplace(data)
                            }
                            setIsWorking(false)
                        })
                        .catch((e) => {
                            
                            setIsWorking(false)
                        })
                        break;
                    case "Level":
                        MarketplaceService.getMarketplace(0, undefined, undefined, undefined, "htl")
                        .then((data: any) => {                            
                            
                            if(data) {
                                setMarketplace(data)
                            }
                            setIsWorking(false)
                        })
                        .catch((e) => {
                            
                            setIsWorking(false)
                        })
                        break;
                }

            }}
        >
            Low To High
        </button>
    </div>
    : null
    }
    
    
   </button>
  )
}

export default SortSelect