import { toast } from "react-toastify"
import { GameClient } from "../../client"
import GameRoutes from "./routes"

export const sendMining = async (minerTeam: string) => {    
    const response = await GameClient.post(
        `${GameRoutes.game}`,
        {
            minerTeam
        }
    )
        
    if (response.status !== 200 || response.data.status !== 200) {
        toast("Mining Error!")        
        return
    } 
    toast(`Mining Started!`)
    return response.data.data
}

export const getDashboard = async () => {
    const response = await GameClient.get(`${GameRoutes.game}${GameRoutes.dashboard}`)
    
    if (response.status !== 200 || response.data.status !== 200) {
        toast("Dashboard Error!")        
        return
    }     
    return response.data.data

}

export const getHistory = async () => {
    const response = await GameClient.get(`${GameRoutes.game}${GameRoutes.history}`)
    
    if (response.status !== 200 || response.data.status !== 200) {
        toast("History Error!")        
        return
    }     
    return response.data.data

}

export const getLootable = async () => {
    const response = await GameClient.get(`${GameRoutes.game}${GameRoutes.lootable}`)

    if (response.status !== 200 || response.data.status !== 200) {
        toast("Lootable Error!")        
        return
    }     
    return response.data.data
}

export const sendLooting = async (looter: string, looterTeam: string, gameId: string) => {
    const response = await GameClient.patch(
        `${GameRoutes.game}${GameRoutes.loot}/${gameId}`,
        {
            looter,
            looterTeam,
            looterBoost: null
        }
    )

    if (response.status !== 200 || response.data.status !== 200) {
        toast("Loot Error!")        
        return
    }     
    toast("Team Sent To Loot!")        
    return response.data.data
}

export const boost = async (gameId: string, inventoryId: string) => {
    const response = await GameClient.patch(`${GameRoutes.game}${GameRoutes.boost}/${gameId}/${inventoryId}`)

    if (response.status !== 200 || response.data.status !== 200) {
        toast("Boost Error!")        
        return
    }     
    
    return response.data.data
}

export const endGame = async (gameId: string) => {
    const response = await GameClient.patch(`${GameRoutes.game}${GameRoutes.end}/${gameId}`)

    if (response.status !== 200 || response.data.status !== 200) {
        toast("End Game Error!")        
        return
    }     
    
    return response.data.data
}


export const endLoot = async (gameId: string) => {
    const response = await GameClient.patch(`${GameRoutes.game}${GameRoutes.loot}${GameRoutes.end}/${gameId}`)
    
    if (response.status !== 200 || response.data.status !== 200) {
        toast("End Loot Error!")        
        return
    }     
    
    return response.data.data
}