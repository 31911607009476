import React, { useEffect, useState } from 'react'
import { ItemDuckFemale, ItemDuckLegendary, ItemDuckMale, SwardIcon } from '../../assets'
import './explore-card.css'
import { Game } from '../../data'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { State } from '../../state/reducers'
import UserState from '../../state/reducers/state-types/user'
import { useScreenSize } from '../../utils'


type Props = {
  setIsSendTeamModalOpen: (status: boolean) => void,
  setGameType: (type: string) => void,  
  setSelectedGame: (game?: string) => void,  
  game: Game,
  index: number,
  isHistory: boolean
}

function ExploreCard(props: Props) {

  

  const calculateTimeLeft = () => {
    const difference = +new Date(props.game.miningEndsAt) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

  const userState = useSelector((s: State) => s.user as UserState)

  const screenSize = useScreenSize()

  useEffect(() => {
    if(!props.isHistory) {
      const timer = setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        setTimeLeft(newTimeLeft);
  
        if (Object.keys(newTimeLeft).length === 0) {
          clearInterval(timer);                        
        }
      }, 1000);
  
      return () => clearInterval(timer);
    }
   
  }, [timeLeft]);

  return (
    <div className='explore-grid-card'>
    <div className='explore-card-info'>
        <span className='mine-header'>Mine {props.index + 1}</span>
        <span className='active'
          style={{
            color: props.isHistory ? (props.game.miner.id === userState.me?.id ? (props.game.minerReward === 340 ? "var(--green)" : "red") : ((props.game.looterReward === 0 ? "red" : "var(--green)"))) : "var(--green)"
          }}
        >{props.isHistory ? (props.game.miner.id === userState.me?.id ? (props.game.minerReward === 340 ? "VICTORY" : "DEFEAT") : ((props.game.looterReward === 0 ? "DEFEAT" : "VICTORY"))) : "ACTIVE"}</span>
    </div>
    <div className='explore-card-action-row'>
      <div className='explore-card-ducks-row'>
        {props.game.minerTeam.ducks.map((d) => (
           <div key={d._id}>
           <img alt='duck' src={d.duckType === "male" ? ItemDuckMale : (d.duckType === "female" ? ItemDuckFemale : ItemDuckLegendary)}/>
           <span>Lv.{d.level}</span>
         </div>
        ))}
                  
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8
        }}
      >
        <img 
          src={SwardIcon}
          width={screenSize.width < 1024 ? 16 : 32}
          height={screenSize.width < 1024 ? 16 : 32}
          alt='sword'
        />
      <span
        style={{
          fontSize: screenSize.width < 1024 ? 16 : 24,
          color: "white"
        }}
      >
        {props.game.minerTeam.ducks.map((d) => d.power).reduce((p, c) => p+c)}
      </span>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          gap: 16
        }}
      >
      {
        !props.isHistory 
        ?
        <span className='explore-card-remaining-time'>{`0${timeLeft.hours}:${timeLeft.minutes < 10 ? "0"+timeLeft.minutes : timeLeft.minutes}:${timeLeft.seconds < 10 ? "0"+timeLeft.seconds : timeLeft.seconds}`}</span>
        :
        null
      }      
         {
        props.isHistory 
        ?
        null
        :
        (
          <button
          onClick={() => {          
  
            if(timeLeft.hours === 0) {
              toast("No Time To Loot!")
              return
            }
            
            props.setGameType("looting")      
            props.setSelectedGame(props.game._id)    
            props.setIsSendTeamModalOpen(true)
          }}
        >
          LOOT
        </button>
        )
      }
      </div>
     
     
   
    
    </div>
</div>
  )
}

export default ExploreCard