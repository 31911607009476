import React from 'react'
import './inventory.css'
import {  IdleDucks } from '../../../assets'
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../state/reducers'
import UserState from '../../../state/reducers/state-types/user'
import { ItemService, UserService } from '../../../api'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { ItemCard, SelectFilter } from '../../../components'



function Inventory() {
  
  const userState = useSelector((s: State) => s.user as UserState)
  const dispatch = useDispatch()
  const {setInventory} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
  const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)


  const open = (inventoryId: string, itemType: string) => {
    setIsWorking(true)
    ItemService.open(inventoryId, itemType)
    .then((r) => {
      UserService.getInventory(0)
      .then((data) => {        
        if(data) {
          setInventory(data)
        }
        setIsWorking(false)
      })
      .catch((e) => {
        setIsWorking(false)
      })
   
    })
    .catch((e) => {
      setIsWorking(false)
    })
  }

  if(userState.inventory.length === 0) {
    return (
      <div className='empty-wrapper'>
        <img src={IdleDucks} alt='idle-ducks'/>
        <span>There is no items yet.</span>
      </div>
    )
  }

  

  return (
    <>
    <div id='inventory-header-row'>
      <span id='inventory-header'>Inventory</span>
      <SelectFilter
        options={[
          "All",
          "Duck",
          "Egg",
          "NFT Crate",
          "Potion Chest",
          "Potion"
        ]}
        type='inventory'
      
      />
    </div>
    
     <section
      id='inventory'    
    > 
      {userState.inventory.map((i) => (
        <ItemCard key={i.id} open={i.item.itemType === "egg" || i.item.itemType === "potion-chest" || i.item.itemType === "nft-crate" ? open : undefined} inventory={i}/>
      ))}
    </section>
    </>
   
  )
}

export default Inventory