import { toast } from "react-toastify"
import { ItemClient } from "../../client"
import ItemRoutes from "./routes"

export const breed = async (maleDuck: string, femaleDuck: string, legendaryBoost: boolean) => {
    const response = await ItemClient.post(
        `${ItemRoutes.item}${ItemRoutes.duck}${ItemRoutes.breed}`,
        {
            maleDuck,
            femaleDuck,
            legendaryBoost
        }
    )
    
    if(response.status !== 200 || response.data.status !== 200) {
        toast("Network Error Breed")
        return
    }
    return response.data.data
}

export const open = async (inventoryId: string, itemType: string) => {
    const response = await ItemClient.get(`${ItemRoutes.item}/${itemType}${ItemRoutes.open}/${inventoryId}`)
    
    if(response.status !== 200 || response.data.status !== 200) {
        toast("Network Error Open")
        return
    }
    return response.data.data
}

export const levelUp = async (inventoryId: string) => {
    const response = await ItemClient.patch(`${ItemRoutes.item}${ItemRoutes.duck}${ItemRoutes.levelUp}/${inventoryId}`)

    if(response.status !== 200 || response.data.status !== 200) {
        toast("Network Error Level Up")
        return
    }
    return response.data.data
} 
