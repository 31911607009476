import { UserActionType } from "../action-types"
import UserState from "./state-types/user"

const initalState: UserState = {
    me: undefined,
    inventory: [],
    teams: []
}


interface Action {
    type: string,
    payload: any
}


const reducer = (state: any = initalState, action: Action) => {
    switch(action.type) {
        case UserActionType.SET_ME:            
            if(!action.payload.transactions) {
                action.payload.transactions = []
            }
            return {...state, me: action.payload}
        case UserActionType.SET_INVENTORY:
            return {...state, inventory: action.payload}
        case UserActionType.SET_TEAMS:
            return {...state, teams: action.payload}
        default: 
            return state  
    }
}

export default reducer