import { KONG_URL } from "../../../constants/constants";

const ItemRoutes = {
    baseUrl : KONG_URL,
    item : "/item",
    duck : "/duck",
    breed : "/breed",
    open : "/open",
    levelUp : "/level-up"
}

export default ItemRoutes