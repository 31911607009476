import { Dispatch } from "redux"
import { Vault } from "../../data"
import { VaultActions } from "../actions"
import { VaultActionType } from "../action-types"

export const setVault = (data: Vault)  => {
    return (dispatch:  Dispatch<VaultActions.SetVaultAction>) => {
        dispatch({
            type: VaultActionType.SET_VAULT,
            payload: data
        })
    }
}