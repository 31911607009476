import Web3, { Uint256 } from "web3";
import USDT_ABI from './abi/usdt.json'

export const  sendUSDT = async (from: string, to: string, value: number)  => {        
    const web3 = new Web3();
        
    
    const TRANSFER_FUNCTION_ABI =  {
        "constant": false,
        "inputs": [
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }

    const encodedCall = web3.eth.abi.encodeFunctionCall(TRANSFER_FUNCTION_ABI, [            
        to,
        value
    ]);
                                
    const transactionParameters = {
        from,
        to: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        data: encodedCall,
    };
    const response = await window.ethereum?.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
    })
    
   
    
    return response
}