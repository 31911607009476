import { toast } from "react-toastify"
import { AuthClient } from "../../client"
import AuthRoutes from "./routes"

export const login = async (email: string, password: string) => {
    const response = await AuthClient.post(
            `${AuthRoutes.auth}${AuthRoutes.login}`,
            {
                email,
                password
            }
        )
    
    if (response.status !== 200 || response.data.status !== 200) {
        toast("Login Error!")        
        return undefined
    }     
    
    return response.data.data

}

export const register = async (email: string, password: string, usedReferenceCode: string | undefined) => {
    const response = await AuthClient.post(
            `${AuthRoutes.auth}${AuthRoutes.register}`,
            {
                email,
                password,
                usedReferenceCode
            }
        )

    return response.data
}

export const sendConfirmation = async (userId: string) => {
    
    const response = await AuthClient.get(`${AuthRoutes.auth}${AuthRoutes.sendConfirmation}/${userId}`)

    if (response.status !== 200 || response.data.status !== 200) {
        toast("Send Confirmation Error!")        
        return
    }     
    
    return true
}

export const confirm = async (userId: string, confirmationCode: string) => {
    const response = await AuthClient.get(`${AuthRoutes.auth}${AuthRoutes.confirm}/${userId}/${confirmationCode}`)

    if (response.status !== 200 || response.data.status !== 200) {        
        return
    }     
    
    return response.data.data
}

export const changePassword = async (email: string, oldPassword: string, newPassword: string) => {
    const response = await AuthClient.patch(
        `${AuthRoutes.auth}${AuthRoutes.changePassword}`,
        {
            email,
            newPassword,
            oldPassword
        }
    )

    if (response.status !== 200 || response.data.status !== 200) {
        toast("Change Password Error!")        
        return
    }     
    
    return response.data.data
}
