import { toast } from "react-toastify"
import { VaultClient } from "../../client"
import VaultRoutes from "./routes"

export const getVaultByCoinName = async (coinName: string) => {
    
    const response = await VaultClient.get(`${VaultRoutes.vault}${VaultRoutes.getByCoinName}/${coinName}`)
    
    if (response.status !== 200 || response.data.status !== 200) {
        toast("Network Error Vault!")
        return null
    }

    return response.data.data
}