import React from 'react'
import './buy-team-slot.css'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../state'
import { UserService } from '../../../../api'

type Props = {
    setIsBuyTeamSlotModalOpen: (status: boolean) => void
}

function BuyTeamSlot(props: Props) {

    const dispatch = useDispatch()
    const {setMe} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
    const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

    const handleBuyTeamSlot = () => {
        setIsWorking(true)
        UserService.buyTeamSlot()
        .then((r) => {
            UserService.getMe()
            .then((data) => {
                setMe(data)
                props.setIsBuyTeamSlotModalOpen(false)
                setIsWorking(false)
            })
            .catch((e) => {                
                setIsWorking(false)
            })
        })
        .catch((e) => {
            setIsWorking(false)
        })
    }
    

  return (
    <div 
        className='buy-team-slot'
        onClick={(e) => {
            e.stopPropagation()
        }}
    >
           <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <div className='close-modal-row'>
                <button
                  style={{
                    fontSize: 32,
                    color: "white",
                    border: "none"
                  }}
                  onClick={() => props.setIsBuyTeamSlotModalOpen(false)}
                >
                  &#x2715;
                </button>
            </div>
        </div>
        <span>Do You Want To Buy Extra Slot For 9500 $DUCKY</span>
        <button
            onClick={() => handleBuyTeamSlot()}
        >
            CONFIRM
        </button>
    </div>
  )
}

export default BuyTeamSlot