
import React,{useRef,useEffect} from 'react';
const Vivus = require('vivus');


const SvgIconCom = ({icon,id}:{icon: string;id:string}) => {
  const svgRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentSvgRef = svgRef.current;

    if (currentSvgRef) {
      const vivusInstance = new (Vivus as any)(currentSvgRef, {
        duration: 180,
        file: icon,
      });

      const handleMouseEnter = () => {
        vivusInstance.reset().play();
      };

      currentSvgRef.addEventListener("mouseenter", handleMouseEnter);

      return () => {
        currentSvgRef.removeEventListener("mouseenter", handleMouseEnter);
      };
    }
  }, [icon]);
  return (
    <div className="svg-icon" id={id} ref={svgRef} />
  );
};

export default SvgIconCom;