import React from 'react'
import './create-edit-team-modal.css'
import { ItemDuckFemale, ItemDuckLegendary, ItemDuckMale, QuestionMark, SwardIcon } from '../../../../assets'
import { Duck } from '../../../../data'


type Props = {
  selectedDucks: (Duck | undefined)[],
  setIsSelectDuckModalOpen: (status: boolean) => void,
  setIsCreateEditTeamModalOpen: (status: boolean) => void,
  setCurrentDuckIndex: (index: number) => void,
  create: () => void,
  setTeamName: (data: string) => void
}

function CreateEditTeamModal(props: Props) {
  return (
    <div 
      className='create-edit-team-body'
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
       <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <div className='close-modal-row'>
                <button
                  style={{
                    fontSize: 32,
                    color: "white",
                    border: "none"
                  }}
                  onClick={() => props.setIsCreateEditTeamModalOpen(false)}
                >
                  &#x2715;
                </button>
            </div>
        </div>
      <div className='create-edit-team-input-group'>
        <span>Team Name</span>
        <input 
          type='text' 
          placeholder='Team Name..'
          onChange={(e) => {
            props.setTeamName(e.currentTarget.value)
          }}
        />
      </div>

      <div className='create-edit-team-ducks-wrapper'>
        <span>Ducks</span>
        <div className='create-edit-team-ducks'>
          {props.selectedDucks.map((d, index) => {
            if(d) {
              return (
                <div className='create-edit-team-ducks-card' key={d._id}>
                <img  alt='duck' src={d.duckType === "male" ? ItemDuckMale : d.duckType === "female" ? ItemDuckFemale : ItemDuckLegendary}/>
                <div className='create-edit-team-duck-info-wrapper'>
                  <span>
                    Lv.{d.level}
                  </span>
                  <div className='duck-power'>
                    <img src={SwardIcon} alt='sword'/>
                    <span>{d.power}</span>
                  </div>
                  <div className='duck-number'>
                    #{d.number}
                  </div>
                </div>
                <button 
                  onClick={() => {
                    props.setCurrentDuckIndex(index)
                    props.setIsSelectDuckModalOpen(true)
                  }}
                >
                  SELECT
                </button>
              </div>       
              )
            }

            return (
              <div className='create-edit-team-ducks-card'>
              <img 
              style={{
                width: 64,
                height: 64
              }}
                src={QuestionMark}
                alt='question'
              />
              <div className='create-edit-team-duck-info-wrapper'>
                <span>
                  -
                </span>
                <div className='duck-power'>
                  <img alt='sword' src={SwardIcon}/>
                  <span>-</span>
                </div>
                <span className='duck-number'>
                  -
                </span>
              </div>
              <button
                    onClick={() => {
                      props.setCurrentDuckIndex(index)
                      props.setIsSelectDuckModalOpen(true)
                    }}
              >
                SELECT
              </button>
            </div>       
            )
          })}
                       
        </div>
      </div>

      <button 
        className='team-create-button'
        onClick={() => {
            props.create()
        }}
      >
        CREATE
      </button>
    </div>
  )
}

export default CreateEditTeamModal