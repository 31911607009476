import React from 'react'
import './manage-teams-card.css'

import { Duck, Team } from '../../data'
import { ItemDuckFemale, ItemDuckLegendary, ItemDuckMale } from '../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../state'
import { UserService } from '../../api'
import { State } from '../../state/reducers'
import GameState from '../../state/reducers/state-types/game'
import UserState from '../../state/reducers/state-types/user'
import { toast } from 'react-toastify'


type Props = {
    team: Team    
}

function ManageTeamsCard(props: Props) {

    const dispatch = useDispatch()

    const {setTeams} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
    const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
    const gameState = useSelector((s : State) => s.game as GameState)
    const userState = useSelector((s: State) => s.user as UserState)
    
    return (
      <div className='manage-teams-card'>
        <span className='manage-teams-card-name'>{props.team.name}</span>
        <div className='manage-teams-card-action-row'>
            <div className='manage-teams-ducks'>
                {props.team.ducks.map((d) => (
                    <div key={d._id}>
                        <span
                        className='duck-id-span'
                       
                        >#{d.number}</span>
                        <img alt='duck' src={d.duckType === 'male' ? ItemDuckMale : (d.duckType === 'female' ? ItemDuckFemale : ItemDuckLegendary)} />
                        <span>Lv.{d.level}</span>
                    </div>
                ))}                
            </div>
            <span className='manage-teams-card-power'>Power: {props.team.ducks.map((d) => d.power).reduce((p, c) => p + c)}</span>
            <button
                onClick={() => {
                    setIsWorking(true)
                    let allTeams: Team[] = []

                    for(const game of gameState.dashboard) {
                        
                            if(game.miner.id === userState.me?.id) {                                
                               
                                allTeams.push(game.minerTeam)
                            } else {
                               
                                allTeams.push(game.looterTeam!)
                            }
                    }

                    

                    let allTeamIds = allTeams.map((t) => t._id)

 

                    if(allTeamIds.includes(props.team._id)) {
                        toast("Team currently playing a game!")
                        setIsWorking(false)
                        return
                    }
                    

                    UserService.deleteTeam(props.team._id)
                    .then((r) => {
                        UserService.getTeams(0)
                        .then((data: any) => {
                            if(data) {
                                setTeams(data)
                            } else {
                                setTeams([])
                            }
                            
                            setIsWorking(false)
                        })
                        .catch((e) => {
                            setIsWorking(false)
                        })
                    })
                    .catch((e) => {
                        setIsWorking(false)
                    })
                }}
            >
                DELETE
            </button>
        </div>
      </div>
    )
}

export default ManageTeamsCard