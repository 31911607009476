import React, { useState } from "react";
import { VideoBg } from "../../assets";



const VideoArea = () => {
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);
  return (
    <>
      <section
        className="video__area video-bg tg-jarallax"
        style={{ backgroundImage: `url(${VideoBg})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-11">
              <div className="video__content text-center">
                <h2 className="title">
                  JOIN THE <span>COMMUNITY</span>
                </h2>
                <p>Join our Telegram community</p>
                <a
                  href="https://t.me/duckyvucky1"
                  target="_blank"
                  className="video__btn tg-btn-1"
                >
                  <span>join telegram</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

   
    </>
  );
};

export default VideoArea;
