import React from 'react'
import './breed-modal.css'
import { HeartIcon, ItemDuckFemale, ItemDuckLegendary, ItemDuckMale, QuestionMark, SwardIcon } from '../../../../assets'
import { Duck } from '../../../../data'


type Props = {
  ducks: Duck[],
  maleDuck: Duck | undefined,
  femaleDuck: Duck | undefined,
  selectDuck: (data: Duck) => void,
  breed: (legendaryBoost: boolean) => void,
  setIsBreedModalOpen: (status: boolean) => void
}

function BreedModal(props: Props) {
  return (
    <div className='breed-modal-body'
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
               <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <div className='close-modal-row'>
                <button
                  style={{
                    fontSize: 32,
                    color: "white",
                    border: "none"
                  }}
                  onClick={() => props.setIsBreedModalOpen(false)}
                >
                  &#x2715;
                </button>
            </div>
        </div>
      <div className='breed-modal-breed-section'>
        <span className='ducks-to-breed'>Ducks To Breed</span>
        <div className='breed-card'>
          <div className='breed-card-duck'>
            {props.maleDuck ? <img src={ItemDuckMale} alt='duck'/> : <img src={QuestionMark} alt='duck'/>}
            {props.maleDuck ? (
              <div className='breed-duck-info-row'>
                <span>Lv.{props.maleDuck?.level}</span>
                <div className='breed-modal-sword'>
                  <img src={SwardIcon} alt='sword'/>
                  <span>{props.maleDuck?.power}</span>
                </div>
                <div className='breed-modal-duck-number'>
                  #{props.maleDuck?.number}
                </div>
              </div>
            ) :
              null}

          </div>
          <img src={HeartIcon} className='breed-heart' alt='heart'/>
          <div className='breed-card-duck'>
            {props.femaleDuck ? <img src={ItemDuckFemale} alt='duck'/> : <img src={QuestionMark} alt='question'/>}
            {props.femaleDuck ? (
              <div className='breed-duck-info-row'>
                <span>Lv.{props.femaleDuck?.level}</span>
                <div className='breed-modal-sword'>
                  <img src={SwardIcon} alt='sword'/>
                  <span>{props.femaleDuck?.power}</span>
                </div>
                <div className='breed-modal-duck-number'>
                  #{props.femaleDuck?.number}
                </div>
              </div>
            ) : null}

          </div>
        </div>


      
     


      </div>
      <div className='breed-modal-ducks-section'>
          <span className='ducks-to-breed'>Ducks</span>
          <div className='breed-modal-ducks-grid'>
            {props.ducks.map((d, index) => (
              <div 
                className='breed-modal-grid-card'
                key={d._id}
              >
                <img className='breed-modal-grid-card-duck-image' alt='duck' src={d.duckType === "male" ? ItemDuckMale : (d.duckType === "female" ? ItemDuckFemale : ItemDuckLegendary)} />
                <div className='breed-duck-info-row'>
                  <span>Lv.{d.level}</span>
                  <div className='breed-modal-sword'>
                    <img src={SwardIcon} alt='sword'/>
                    <span>{d.power}</span>
                  </div>
                  <div className='breed-modal-duck-number'>
                    #{d.number}
                  </div>
                </div>
                <button
                  onClick={() => {
                    props.selectDuck(d)                    
                  }}
                >
                  SELECT
                </button>
              </div>
            ))}




          </div>
        </div>

      <div className='breed-actions'>
              <button
                  onClick={() => {
                    props.breed(false)
                  }}
              >
                BREED (14500 $DUCKY)
              </button>

              <button
                style={{
                  backgroundColor: "var(--green)",
                  color: "black"
                }}
                onClick={() => {
                  props.breed(true)                  
                }}
              >
                BREED WITH LEGENDARY BOOST (+4500 $DUCKY)
              </button>
        </div>
    </div>
  )
}

export default BreedModal