import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './confirm-account.css'
import { AuthService } from '../../api';

function ConfirmAccount() {
  const { userId, confirmationCode } = useParams();
  const [isConfirmed, setIsConfirmed] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    sleep(3000)
    if(!isConfirmed) {
     AuthService.confirm(userId!, confirmationCode!)
     .then((r) => {
        navigate("/login")
     })
     .catch((e) => {
     })     
      setIsConfirmed(true)
    }
   
  }, [])

  async function sleep(ms: number) {
    await new Promise(r => setTimeout(r, ms));
  }

  return (
    <div className='confirm-account-wrapper'>
      <span className="confirm-loader"></span>
      <span className='confirm-text'>Wait For Your Account To Be Confirmed</span>
    </div>
  )
}

export default ConfirmAccount