import React from 'react'
import './confirm-modal.css'

type Props = {
    text: string,
    actionCallback: Function,
    callback: (status: boolean) => void


}

function ConfirmModal(props: Props) {
  return (
    <div
      className='confirm-body'
      onClick={(e) => e.stopPropagation()}
    >
      <div className='close-modal-row'>
                <button
                    onClick={() => props.callback(false)}
                >
                    &#x2715;
                </button>
      </div>

      <div className='confirm-content'>
        <span style={{textAlign: "center"}}>{props.text}</span>
        <div className='confirm-action-row'>
        <button
          style={{
            backgroundColor: "green",            
          }}
          onClick={() => props.actionCallback()}
        >
          CONFIRM
        </button>
        <button
           style={{
            backgroundColor: "red",            
          }}
          onClick={() => {
            props.callback(false)
          }}
        >
          CANCEL
        </button>
        </div>
      </div>

    
    </div>
  )
}

export default ConfirmModal