import React, { useState } from 'react'
import './payment.css'
import { useScreenSize } from '../../../utils';

import {  PaymentCard } from '../../../components';
import { useSelector } from 'react-redux';
import { State } from '../../../state/reducers';
import WalletState from '../../../state/reducers/state-types/wallet';
type Props = {
    isEmpty: boolean,
    forMobile: boolean
}
function Payment(props: Props) {
    const screenSize = useScreenSize();
    const [showDepositCard, setShowDepositCard] = useState(false);
    const [showDWithdrawCard, setShowDWithdrawCard] = useState(true);

    const walletState = useSelector((s: State) => s.wallet as WalletState)

    return (
        <section
            id='payment'
            style={{
                flexDirection: props.forMobile ? 'column' : 'row',
                justifyContent: props.forMobile ? 'normal' : 'space-between',
                alignItems: 'center'

            }}
        >
            {
                screenSize.width > 1024 ?
                    <>
                        <PaymentCard cardTitle='Deposit' moneyCode='USDT' walletCode={walletState.wallet ?? ""} type='DEPOSIT'/>
                        <PaymentCard cardTitle='Withdraw' moneyCode='USDT' walletCode={walletState.wallet ?? ""} type='WITHDRAW'/>
                    </>
                    :
                    <>
                    <div id='mobile-payment-nav-links-container'>
                            <div className='mobile-payment-nav-link-button' id={showDepositCard ? 'active-button' : 'not-active-button'} onClick={() => { setShowDepositCard(true); setShowDWithdrawCard(false) }}>
                                <p>DEPOSIT</p>
                            </div>
                            <div className='mobile-payment-nav-link-button' id={showDWithdrawCard ? 'active-button' : 'not-active-button'} onClick={() => { setShowDepositCard(false); setShowDWithdrawCard(true) }}>
                                <p>WITHDRAW</p>
                            </div>
                        </div>
                        
                        {
                            showDepositCard
                            ?
                            <PaymentCard cardTitle='Deposit' moneyCode='USDT' walletCode={walletState.wallet ? walletState.wallet!.substring(0,30): ""} type='DEPOSIT'/>
                            :
                            <PaymentCard cardTitle='Withdraw' moneyCode='USDT' walletCode={walletState.wallet ? walletState.wallet!.substring(0,30): ""} type='WITHDRAW'/>
                          
                        }
                    </>
            }
        </section>
    )
}

export default Payment