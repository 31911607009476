import { Dispatch } from "redux"
import { WalletActions } from "../actions"
import { WalletActionType } from "../action-types"

export const setWallet = (data: string | undefined)  => {
    return (dispatch:  Dispatch<WalletActions.SetWalletAction>) => {
        dispatch({
            type: WalletActionType.SET_WALLET,
            payload: data
        })
    }
}