import React, { useEffect, useState } from 'react'
import './dashboard-working-card.css'
import { ChestIcon, ClockIcon, ItemDuckFemale, ItemDuckLegendary, ItemDuckMale, ItemPotionBlue, ItemPotionGreen, ItemPotionRed, PotionVector, SwardIcon } from '../../../assets'
import { Game, User } from '../../../data'
import { useDispatch} from 'react-redux'
import { GameService, UserService } from '../../../api'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { toast } from 'react-toastify'


type Props = {
  game: Game,
  user: User,
  setIsPotionModalOpen: (status: boolean) => void,
  setSelectedGameId: (data: string) => void
}


function DashboardWorkingCard(props: Props) {  
  const dispatch = useDispatch()
  const {setDashboard} = bindActionCreators(actionCreators.GameActionCreators, dispatch)
  const {setTeams, setMe} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
  const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
  var looterPower = props.game.looter ? props.game.looterTeam?.ducks.map((d) => d.power).reduce((p,c) => p+c) : 0
  var minerPower = props.game.minerTeam.ducks.map((d) => d.power).reduce((p,c) => p+c)

  if(props.game.minerBoost) {
    minerPower += props.game.minerBoost!.power
  }

  if(props.game.looterBoost) {
    looterPower! += props.game.looterBoost!.power
  }




  

  
  

  const calculateTimeLeft = () => {
    const difference = +new Date(props.game.miner.id === props.user.id ? props.game.miningEndsAt : props.game.lootingEndsAt!) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (Object.keys(newTimeLeft).length === 0) {
        clearInterval(timer);
                
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);


  const endLootingOrMining  = async () => {
    setIsWorking(true)
    if(props.game.miner.id === props.user.id) {
      GameService.endGame(props.game._id)
        .then((r) => {
          GameService.getDashboard()
          .then((data: any) => {
            if(data) {
              setDashboard(data)
            } else {
              setDashboard([])
            }            
          })
          .catch((e) => {
            setIsWorking(false)
          })
          UserService.getTeams(0)
          .then((data) => {
            if(data) {
              setTeams(data)              
            } else {
              setTeams([])
            }
            setIsWorking(false)
          })
          .catch((e) => {
            setIsWorking(false)
          })

          UserService.getMe()
          .then((me) => {            
            setMe(me)
          })
          .catch((e) => {

          })
        })
        .catch((e) => {
          setIsWorking(false)
        })
    } else {
      
      GameService.endLoot(props.game._id)
        .then((r) => {
          GameService.getDashboard()
          .then((data: any) => {
            if(data) {
              setDashboard(data)
            } else {
              setDashboard([])
            }
            
          })
          .catch((e) => {
            setIsWorking(false)
          })
          UserService.getTeams(0)
          .then((data) => {
            if(data) {
              setTeams(data)              
            } else {
              setTeams([])
            }
            setIsWorking(false)
          })
          .catch((e) => {
            setIsWorking(false)
          })

          UserService.getMe()
          .then((me) => {
            setMe(me)
          })
          .catch((e) => {

          })

        })
        .catch((e) => {
          setIsWorking(false)
        })
    
    }
    
  }

    

 return(
  <div className='dashboard-card'>
    <div className='dashboard-duck-section'>
      <span>{props.game.miner.id === props.user.id ? props.game.minerTeam.name : props.game.looterTeam!.name}</span>
      {
        props.game.miner.id === props.user.id ?  props.game.minerTeam.ducks.map((d) => (
          <div className='dashboard-duck-info-row' key={d._id}>
            <img  alt='duck'src={d.duckType === "female" ? ItemDuckFemale : (d.duckType === "male" ? ItemDuckMale : ItemDuckLegendary)}/>
            <span>Lv.{d.level}</span>
           </div> 
          
        )) : props.game.looterTeam!.ducks.map((d) => (
          <div className='dashboard-duck-info-row' key={d._id}>
          <img alt='duck' src={d.duckType === "female" ? ItemDuckFemale : (d.duckType === "male" ? ItemDuckMale : ItemDuckLegendary)}/>
          <span>Lv.{d.level}</span>
         </div> 
          
        ))
      }
    </div>
    <div className='dashboard-info-section'>
      <span className='dashboard-info-section-header'>{props.game.miner.id === props.user.id ? "MINING" : "LOOTING"}</span>
      <div className='dashboard-info-section-row'>
        <img alt='ches' src={ChestIcon}/>
        <span>{props.game.miner.id === props.user.id ? (minerPower > looterPower! ? "340" : "170") : (looterPower! > minerPower ? "170" : "0")}</span>
      </div>
      <div className='dashboard-info-section-row'>
        <img alt='clock' src={ClockIcon}/>
        {
          timeLeft.seconds === 0 && timeLeft.minutes === 0 && timeLeft.hours === 0
          ?
          <span>--:--:--</span> 
          :
          <span>{`0${timeLeft.hours}:${timeLeft.minutes < 10 ? "0"+timeLeft.minutes : timeLeft.minutes}:${timeLeft.seconds < 10 ? "0"+timeLeft.seconds : timeLeft.seconds}`}</span>
          
        }
        
      </div>      
      <div className='dashboard-info-section-row'>
        <img  alt='sword' src={SwardIcon}/>
        <span>{props.game.miner.id === props.user.id ? props.game.minerTeam.ducks.map((d) => d.power).reduce((p, c) => p + c) : props.game.looterTeam!.ducks.map((d) => d.power).reduce((p, c) => p + c)}</span>
      </div>
    </div>
    <div className='dashboard-potion-section'>
      {
        props.game.miner.id === props.user.id ?
        (
          timeLeft.seconds === 0 && timeLeft.minutes === 0 && timeLeft.hours === 0
          ?
          <button className='claim-button'
          onClick={() => {
            endLootingOrMining()
          }}
          >
          CLAIM
        </button>
        :
        <span></span>
        ) :  (
          timeLeft.seconds === 0 && timeLeft.minutes === 0 && timeLeft.hours === 0
          ?
          <button className='claim-button'
            onClick={() => {
              
              endLootingOrMining()
            }}
          >
          CLAIM
        </button>
        :
        <span></span>
        ) 
      }

      {
        props.game.miner.id === props.user.id ?
        (
          props.game.looter && !props.game.lootingEnded ?
          <span style={{color: "red"}}>Looter Team Power: {props.game.looterTeam!.ducks.map((d) => d.power).reduce((p, c) => p+c)}</span>
          :
          <span style={{color: "green"}}>Looter Team Power: -</span>
        ):
        null
      }
     
    
      <button 
        className='dahsboard-potion'
        style={{
          opacity: props.game.miner.id === props.user.id ? (props.game.minerBoost ? 1 : 0.3) : ((props.game.looterBoost ? 1 : 0.3))
        }}
        onClick={() => {

          if(!props.game.looter) {
            toast("You Can't Use Potion If There Is No Looter!")
            return
          }

          if(props.game.looter) {
            if(props.game.lootingEnded!) {
              toast("You Can't Use Potion. Looting has ended!")
              return
            }
          }

          if(props.game.miner.id === props.user.id) {
            if(!props.game.minerBoost) {
              props.setSelectedGameId(props.game._id)
              props.setIsPotionModalOpen(true)
            } else {
              toast("Already Boosted!")
            }
          } else {
            if(!props.game.looterBoost) {
              props.setSelectedGameId(props.game._id)
              props.setIsPotionModalOpen(true)
            } else {
              toast("Already Boosted!")
            }
          }
       
        }}
      >
        <img alt='potion' src={props.game.miner.id === props.user.id ? (props.game.minerBoost ? (props.game.minerBoost.subType === "red" ? ItemPotionRed : (props.game.minerBoost.subType === "green" ? ItemPotionGreen : ItemPotionBlue ) ) : PotionVector) : (props.game.looterBoost ? (props.game.looterBoost.subType === "red" ? ItemPotionRed : (props.game.looterBoost.subType === "green" ? ItemPotionGreen : ItemPotionBlue)) : PotionVector)}/>
      </button>
    </div>
  
  </div>
 )
  
}

export default DashboardWorkingCard