import { Dispatch } from "redux"
import { Inventory, Team, User } from "../../data"
import { UserActions } from "../actions"
import { UserActionType } from "../action-types"

export const setMe = (data: User)  => {
    return (dispatch:  Dispatch<UserActions.SetMeAction>) => {
        dispatch({
            type: UserActionType.SET_ME,
            payload: data
        })
    }
}

export const setInventory = (data: Inventory[])  => {
    return (dispatch:  Dispatch<UserActions.SetInventoryAction>) => {
        dispatch({
            type: UserActionType.SET_INVENTORY,
            payload: data
        })
    }
}

export const setTeams = (data: Team[])  => {
    return (dispatch:  Dispatch<UserActions.SetTeamsAction>) => {
        dispatch({
            type: UserActionType.SET_TEAMS,
            payload: data
        })
    }
}
