import { toast } from "react-toastify"
import { MarketplaceClient } from "../../client"
import MarketplaceRoutes from "./routes"



export const getMarketplace = async (skip: number, filter?: string, dateSort?: string, priceSort?: string, levelSort?: string, level?:string) => {
    
    const response = await MarketplaceClient.get(`${MarketplaceRoutes.marketplace}?skip=${skip}&filter=${filter ?? "all"}&dateSort=${dateSort ?? "none"}&priceSort=${priceSort ?? "none"}&levelSort=${levelSort ?? "none"}&level=${level ?? "none"}`)

    if(response.status !== 200 || response.data.status !== 200) {
        toast("Network Error While Getting Marketplace")
        return undefined
    }
    return response.data.data

}

export const buy = async (itemType: string, marketplaceId: string) => {    
    const response = await MarketplaceClient.get(`${MarketplaceRoutes.marketplace}${MarketplaceRoutes.buy}/${itemType}/${marketplaceId}`)

    if(response.status !== 200 || response.data.status !== 200) {
        
        toast("Network Error While Buying Item")
        return undefined
    }
    
    return response.data.data

}

export const sell = async (item: string, seller: string, price: number) => {
    const response = await MarketplaceClient.post(`${MarketplaceRoutes.marketplace}`, {
        item,
        seller,
        price,
        remaining: null,
        quantity: 1,        
    })

    if(response.status !== 200 || response.data.status !== 200) {
        toast("Network Error While Selling Item")
        return undefined
    }
    return response.data.data
}

export const deleteMarketplace = async (itemId: string) => {
    const response = await MarketplaceClient.delete(`${MarketplaceRoutes.marketplace}/${itemId}`)

    if(response.status !== 200 || response.data.status !== 200) {
        toast("Network Error While Deleting Marketplace")
        return undefined
    }
    return response.data.data

}