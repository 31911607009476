import React from 'react'
import './sidebar-button.css'

type Props = {
  data: string,
  icon: string,
  text: string,
  isSelected: boolean,
  onClick: (data: string) => void
}

function SidebarButton(props: Props) {
  return (
    <button className={`sidebar-button-container ${props.isSelected ? 'bg-my-primary' : 'bg-my-secondary'}`} onClick={() => props.onClick(props.data)}>
      <img alt='sidebar' src={props.icon} style={{opacity: props.isSelected ? 1 : .6}} />
      <span className={`${props.isSelected ? 'color-white' : 'color-white60'}`}>{props.text}</span>
    </button>
  )
}

export default SidebarButton