import React from 'react'
import './modal.css'


type ModalProps = {
    isOpen: boolean,
    overlayClickCallback: (state: boolean) => void,
    children: React.JSX.Element
}

function Modal(props: ModalProps) {
  return (
    <div className='overlay'
        style={{
            display: props.isOpen ? "flex" : "none",
            visibility: props.isOpen ? "visible" : 'hidden'
        }}
        onClick={() => {
            props.overlayClickCallback(false)
        }}
        
    >
        {props.children}
    </div>
  )
}

export default Modal