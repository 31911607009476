import React from 'react'
import './select-duck-modal.css'
import { ItemDuckFemale, ItemDuckLegendary, ItemDuckMale, SwardIcon } from '../../../../assets'
import { Duck } from '../../../../data'

type Props = {
  availableDucks: Duck[],
  selectedDucks: (Duck | undefined)[],
  currentDuckIndex: number,
  setIsSelectDuckModalOpen: (status: boolean) => void,
  setSelectedDucks: (duck: (Duck | undefined)[]) => void,  
  
}

function SelectDuckModal(props: Props) {
  return (
    <div 
        className='select-duck-body'
        onClick={(e) => {
            e.stopPropagation()
        }}
    >
               <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <div className='close-modal-row'>
                <button
                  style={{
                    fontSize: 32,
                    color: "white",
                    border: "none"
                  }}
                  onClick={() => props.setIsSelectDuckModalOpen(false)}
                >
                  &#x2715;
                </button>
            </div>
        </div>
        <span className='select-duck-header'>
            Ducks
        </span>
        <div className='select-ducks-wrapper'>
          {props.availableDucks.filter((ad) => !props.selectedDucks.map((sd) => sd?._id).includes(ad._id)).map((d) => (
              <div className='create-edit-team-ducks-card' key={d._id}>
              <img alt='duck' src={d.duckType === "male" ?  ItemDuckMale : (d.duckType === "female" ? ItemDuckFemale : ItemDuckLegendary)}/>
              <div 
                className='create-edit-team-duck-info-wrapper'
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <span
                  style={{
                    color: "white"
                  }}
                >
                  Lv.{d.level}
                </span>
                <div className='duck-power'>
                  <img alt='sword' src={SwardIcon }/>
                  <span
                      style={{
                        color: "white"
                      }}
                  >
                    {d.power}
                  </span>
                </div>
                <div className='duck-number'>
                  #{d.number}
                </div>
              </div>
              <button
                onClick={() => {
                  let newSelectedDucks = props.selectedDucks
                  newSelectedDucks[props.currentDuckIndex] = d
                  console.log(newSelectedDucks)
                  props.setSelectedDucks(newSelectedDucks)
                  props.setIsSelectDuckModalOpen(false)
                }}
              >
                SELECT
              </button>
            </div>       

          ))}
      
       
          
          
        </div>

    </div>
  )
}

export default SelectDuckModal