import React, { useState } from 'react'
import './payment-card.css'
import { useScreenSize } from '../../utils';
import { PaymentButton } from '../../components';
import { TetherUsdtIcon } from '../../assets';
import { TransactionService, UserService, VaultService } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state';
import { BlockchainActions } from '../../block-chain';
import { State } from '../../state/reducers';
import WalletState from '../../state/reducers/state-types/wallet';
import { toast } from 'react-toastify';
import UserState from '../../state/reducers/state-types/user';

type Props = {
    type: string,
    cardTitle: string,
    moneyCode: string,
    walletCode: string
}

function PaymentCard(props: Props) {
    const screenSize = useScreenSize();
    
    const [depositAmount, setDepositAmount] = useState(0)
    const [withdrawAmount, setWithdrawAmount] = useState(0)

    const dispatch = useDispatch()
    const {setVault} = bindActionCreators(actionCreators.VaultActionCreators, dispatch)
    const {setMe} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
    const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
    const walletState = useSelector((s: State) => s.wallet as WalletState)
    const userState = useSelector((s:State) => s.user as UserState)


    
    


    return (
        <>
            {
                screenSize.width > 1024 ?

                    <div id='payment-deposit-container'>
                        <div id='payment-main'>
                            <div id='payment-main-header'>
                                <p>{props.cardTitle}</p>
                                <div id='payment-currency-container'>
                                    <img src={TetherUsdtIcon}  alt='tether'/>
                                    <p>{props.moneyCode}</p>
                                </div>
                            </div>
                            <div id='payment-main-amount'>
                                <span>Amount</span>
                                <div id='inpt'>
                                    <input type="number" placeholder='10.00' 
                                    value={props.type === 'DEPOSIT' ? depositAmount : withdrawAmount}
                                        onChange={(e) => {
                                            if (props.type === 'DEPOSIT') {
                                                setDepositAmount(parseFloat(e.currentTarget.value))
                                            } else {
                                                setWithdrawAmount(parseFloat(e.currentTarget.value))
                                            }
                                        }}
                                    />
                                    <img src={TetherUsdtIcon} alt='tether' />
                                </div>
                                <div id='payment-main-amount-wallet-content'>
                                    <span>Wallet: </span>
                                    <p>{props.walletCode}</p>
                                </div>
                                {
                                    props.type === "WITHDRAW"
                                    ?
                                    (
                                        <>
                                         <div 
                                            id='payment-main-amount-wallet-content'
                                            style={{
                                                marginBottom: 8,
                                                marginTop: 8
                                            }}
                                         >
                                        <span>Comission: </span>
                                        <p>{withdrawAmount * 0.1}</p>
                                    </div>
                                    <div id='payment-main-amount-wallet-content'>
                                        <span>You'll Receive: </span>
                                        <p>{withdrawAmount - (withdrawAmount * 0.1)}</p>
                                    </div>
                                        </>
                                       
                                    
                                    )
                                    :null
                                }
                            
                               
                            </div>
                        </div>
                        <PaymentButton title={props.cardTitle.toUpperCase()} onClick={async () => {
                            if (props.type === "DEPOSIT") {
                                if(!walletState.wallet) {
                                    toast("Connect A Wallet To Make Transactions!")
                                    return
                                }
                                setIsWorking(true)

                                BlockchainActions.sendUSDT(walletState.wallet!, "0x1CDb578Fd767369c9cb6b90D3E470672A4764185", depositAmount * 1000000)
                                .then((r) => {                                    
                                    TransactionService.deposit(depositAmount, walletState.wallet!, (r as string) ?? undefined)
                                    .then((r) => {
                                        UserService.getMe()
                                        .then((data) => {                                                                                    
                                            setMe(data)
                                            setIsWorking(false)
                                        })
                                        .catch((e) => {
                                            setIsWorking(false)
                                        })
                                        VaultService.getVaultByCoinName("ducky")
                                        .then((vault) => {
                                            setVault(vault)
                                            setIsWorking(false)
                                        })
                                        .catch((e) => {
                                            setIsWorking(false)
                                        })
                                    })
                                    .catch((e) => {
                                        setIsWorking(false)
                                    })
                                })
                                .catch((e) => {
                                    setIsWorking(false)
                                })
                                
                             
                                setDepositAmount(0)
                            } else {
                                if(!walletState.wallet) {
                                    toast("Connect A Wallet To Make Transactions!")
                                    return
                                }

                                
                                
                                if(userState.me!.usdtBalance < withdrawAmount) {
                                    toast("Not Enough Balance!")
                                    return
                                }
                                
                                if(withdrawAmount < 60) {
                                    toast("Min Withdraw Amount Is 60 USDT")
                                    return
                                }

                                TransactionService.withdraw(withdrawAmount, walletState.wallet!)
                                .then((r) => {
                                    UserService.getMe()
                                    .then((data) => {                                        
                                        setMe(data)
                                    })
                                })
                                setWithdrawAmount(0)
                            }
                          
                        }}/>
                    </div>
                    :
                    <>
                        
                        <div id='mobile-payment-deposit-container'>
                            <div id='mobile-payment-main'>
                                <div id='mobile-payment-main-header'>
                                    <p>{props.cardTitle}</p>
                                    <div id='mobile-payment-currency-container'>
                                        <img src={TetherUsdtIcon} alt='tether' />
                                        <p>{props.moneyCode}</p>
                                    </div>
                                </div>
                                <div id='mobile-payment-main-amount'>
                                    <span>Amount</span>
                                    <div id='inpt'>
                                        <input type="number" placeholder='10.00' 
                                            value={props.type === 'DEPOSIT' ? depositAmount : withdrawAmount}
                                              onChange={(e) => {
                                                if (props.type === 'DEPOSIT') {
                                                    setDepositAmount(parseFloat(e.currentTarget.value))
                                                } else {
                                                    setWithdrawAmount(parseFloat(e.currentTarget.value))
                                                }
                                            }}
                                        />
                                        <img src={TetherUsdtIcon} alt='tether' />
                                    </div>
                                    <div id='mobile-payment-main-amount-wallet-content'>
                                        <span>Wallet: </span>
                                        <p>{props.walletCode}</p>
                                        
                                    </div>
                                    {
                                    props.type === "WITHDRAW"
                                    ?
                                    (
                                        <>
                                       <div id='mobile-payment-main-amount-wallet-content'>
                                        <span>Comission: </span>
                                        <p>{withdrawAmount * 0.1}</p>
                                        
                                    </div>
                                    <div id='mobile-payment-main-amount-wallet-content'>
                                        <span>You'll Receive: </span>
                                        <p>{withdrawAmount - (withdrawAmount * 0.1)}</p>
                                        
                                    </div>
                                        </>
                                       
                                    
                                    )
                                    :null
                                }
                                    
                                </div>
                            </div>
                            <PaymentButton title={props.cardTitle.toUpperCase()} onClick={() => {
                                if (props.type === "DEPOSIT") {
                                    if(!walletState.wallet) {
                                        toast("Connect A Wallet To Make Transactions!")
                                        return
                                    }

                                    

                                    setIsWorking(true)
    
                                    BlockchainActions.sendUSDT(walletState.wallet!, "0x1CDb578Fd767369c9cb6b90D3E470672A4764185", depositAmount * 1000000)
                                    .then((r) => {                                    
                                        TransactionService.deposit(depositAmount, walletState.wallet!, (r as string) ?? undefined)
                                        .then((r) => {
                                            UserService.getMe()
                                            .then((data) => {                                                                                    
                                                setMe(data)
                                                setIsWorking(false)
                                            })
                                            .catch((e) => {
                                                setIsWorking(false)
                                            })
                                            VaultService.getVaultByCoinName("ducky")
                                            .then((vault) => {
                                                setVault(vault)
                                            })
                                            .catch((e) => {})
                                        })
                                        .catch((e) => {
                                            setIsWorking(false)
                                        })
                                    })
                                    .catch((e) => {
                                        setIsWorking(false)
                                    })
                                    
                                 
                                    setDepositAmount(0)
                                } else {
                                    if(!walletState.wallet) {
                                        toast("Connect A Wallet To Make Transactions!")
                                        return
                                    }

                                    if(userState.me!.usdtBalance < withdrawAmount) {
                                        toast("Not Enough Balance!")
                                        return
                                    }

                                    TransactionService.withdraw(withdrawAmount, walletState.wallet!)
                                    .then((r) => {
                                        UserService.getMe()
                                        .then((data) => {                                        
                                            setMe(data)
                                        })
                                    })
                                    setWithdrawAmount(0)
                                }
                            }} />
                        </div>

                    </>
            }
        </>
    )
}

export default PaymentCard