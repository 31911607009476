import React, { useState } from 'react'
import './dashboard.css'
import { DashboardIdleCard, DashboardWorkingCard, Modal, UsePotionModal } from '../../../components'
import { useScreenSize } from '../../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import GameState from '../../../state/reducers/state-types/game'
import UserState from '../../../state/reducers/state-types/user'
import { RefreshIcon } from '../../../assets'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { GameService } from '../../../api'


function Dashboard() {
  const screenSize = useScreenSize()
  const [isUsePotionModalOpen, setIsUsePotionModalOpen] = useState(false)
  const [selectedGameId, setSelectedGameId] = useState<string | undefined>(undefined)
  const gameState = useSelector((s: State) => s.game as GameState)
  const userState = useSelector((s: State) => s.user as UserState)

  const dispatch = useDispatch()
  const {setDashboard} = bindActionCreators(actionCreators.GameActionCreators, dispatch)
  const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

  

  return (
    <>
      <div
        style={{
          display: "flex",          
          alignItems: "center",
          gap: 16,
          justifyContent: screenSize.width < 1024 ? "space-between" : undefined

        }}
      >
      <span id='dashboard-header'>Live Mines & Loots</span>
      <button
        style={{
          opacity: 0.8,
          marginRight: 8
        }}

        onClick={() => {
          setIsWorking(true)
          GameService.getDashboard()
          .then((dashboard) => {
            if(dashboard) {
              setDashboard(dashboard)
            } else {
              setDashboard([])
            }
            setIsWorking(false)
          })
          .catch((e) => {
            setIsWorking(false)
          })
        }}
      >
        <img width={28} height={28} src={RefreshIcon}/>
      </button>
      </div>
      <section
      id='dashboard'
      style={{
        flexDirection: screenSize.width <= 1024 ? 'column' : (gameState.dashboard.length === 0 ? 'row' : 'column'),
        justifyContent: screenSize.width <= 1024 ?  (gameState.dashboard.length !== 0 ? 'normal' : 'center') : (gameState.dashboard.length === 0 ? 'space-between' : 'normal'),
        alignItems: 'center'

      }}
    >     
      
      {
        gameState.dashboard.length === 0 ? (
          <>
            <DashboardIdleCard header='Mining Expedition' info='No Teams On Mining Right Now' infoP='Send your Ducky to mine for valuable treasure! Earns $DUCKY as rewards.' />
            <DashboardIdleCard header='Looting Mission' info='No Teams On Looting Right Now' infoP='Steal rewards from unaware miners! Earns $DUCKY as rewards.' />
            <DashboardIdleCard header='Build Your Team' info='No Available Team Exists' infoP='Create your Ducky teams to be sent out on expeditions or missions!' />
          </>
        ) : (
          <>
       
            {gameState.dashboard.map((g) => {
              if(userState.me) {
                return <DashboardWorkingCard key={g._id} setSelectedGameId={setSelectedGameId} game={g} user={userState.me!} setIsPotionModalOpen={setIsUsePotionModalOpen}/>                         
              }
              return null              
            }
              
            )}
            
            
          </>
        )
      }

    </section>
    <Modal
      isOpen={isUsePotionModalOpen}
      overlayClickCallback={setIsUsePotionModalOpen}
    >
      <UsePotionModal setIsUsePotionModalOpen={setIsUsePotionModalOpen} selectedGameId={selectedGameId} setSelectedGameId={setSelectedGameId}/>
    </Modal>
    </>
   
  )
}

export default Dashboard