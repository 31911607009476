import axios from "axios";
import TransactionRoutes from "./services/transaction/routes";
import MarketplaceRoutes from "./services/marketplace/routes";
import UserRoutes from "./services/user/routes";
import ItemRoutes from "./services/item/routes";
import GameRoutes from "./services/game/routes";
import VaultRoutes from "./services/vault/routes";
import AuthRoutes from "./services/auth/routes";

const token = localStorage.getItem("token")




export const TransactionClient = axios.create({
  baseURL: TransactionRoutes.baseUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  },
  
});

export const MarketplaceClient = axios.create({
  baseURL: MarketplaceRoutes.baseUrl,
  headers: {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  
});

export const UserClient = axios.create({
  baseURL: UserRoutes.baseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  
});

export const ItemClient = axios.create({
  baseURL: ItemRoutes.baseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  
});

export const GameClient = axios.create({
  baseURL: GameRoutes.baseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  
});

export const VaultClient = axios.create({
  baseURL: VaultRoutes.baseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  
});

export const AuthClient = axios.create({
  baseURL: AuthRoutes.baseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  
});