import React from 'react'
import './send-team-modal.css'
import { ItemDuckFemale, ItemDuckLegendary, ItemDuckMale } from '../../../../assets'
import { Team, User } from '../../../../data'
import { GameService, UserService } from '../../../../api'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../state'
import { State } from '../../../../state/reducers'
import GameState from '../../../../state/reducers/state-types/game'
import { toast } from 'react-toastify'

type Props = {
  teams: Team[],
  gameType: string,
  modalCallback: (status: boolean) => void,
  gameId?: string,
  user: User
}


function SendTeamModal(props: Props) {
  
  const dispatch = useDispatch()  
  const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
  const {setDashboard, setLootable} = bindActionCreators(actionCreators.GameActionCreators, dispatch)
  const {setTeams} = bindActionCreators(actionCreators.UserActionCreators, dispatch)


 

  return (
    <div
      className='send-team-body'
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
         <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <div className='close-modal-row'>
                <button
                  style={{
                    color: "white",
                    fontSize: 32,
                    border: "none"
                  }}
                  onClick={() => props.modalCallback(false)}
                >
                  &#x2715;
                </button>
            </div>
        </div>
      <span className='send-team-header'>TEAMS</span>
      <div
        className='send-teams-wrapper'
      >
        {props.teams.filter(t => t.canPlay).map((t) => (
           <div className='send-team-card'>   

           <div className='send-team-ducks-name'>
             <div className='send-team-card-ducks'>
              {t.ducks.map((d) => (
                 <div className='send-team-card-duck' key={d._id}>
                 <img  alt='duck' src={d.duckType === "male" ? ItemDuckMale : (d.duckType === "female" ? ItemDuckFemale : ItemDuckLegendary)}/>
                 <span>Lv.{d.level}</span>
               </div>
              ))}                
               </div>
               <span>{t.name}</span>
           </div>
   
           <div className='send-team-power-action'>
             <span>Power: {t.ducks.map(d => d.power).reduce((p, c) => p + c)}</span>
             <button
              onClick={() => {

                if(t.ducks.filter((d) => d.onSale === true).length > 0) {
                  toast("Duck Is On Marketplace!")
                  return
                }

                setIsWorking(true)
                if(props.gameType === "mining") {
                  GameService.sendMining(t._id)
                  .then((r) => {
                    GameService.getDashboard()
                    .then((data: any) => {        
                      if(data) {
                        setDashboard(data)
                      }          
                      
                    })
                    .catch((e) => {
                      setIsWorking(false)
                    })
    
                    GameService.getLootable()
                    .then((data: any) => {
                      if(data) {
                        setLootable(data)
                      }                                    
                    })
                    .catch((e) => {
                      setIsWorking(false)
                    })
    
                    UserService.getTeams(0)
                    .then((data) => {
                      if(data) {
                        setTeams(data)
                      }
                      setIsWorking(false)
                      props.modalCallback(false)
                    })
                    .catch((e) => {
                      setIsWorking(false)
                    })
                  })
                  .catch((e) => {
                    setIsWorking(false)
                  })                  
                } else {
                  GameService.sendLooting(props.user.id, t._id, props.gameId!)
                  .then((r) => {
                    GameService.getDashboard()
                    .then((data: any) => {        
                      if(data) {
                        setDashboard(data)
                      }          
                      
                    })
                    .catch((e) => {
                      setIsWorking(false)
                    })
    
                    GameService.getLootable()
                    .then((data: any) => {
                      if(data) {
                        setLootable(data)
                      } else {
                        setLootable([])
                      }                    
                    })
                    .catch((e) => {
                      setIsWorking(false)
                    })
    
                    UserService.getTeams(0)
                    .then((data) => {
                      if(data) {
                        setTeams(data)
                      }
                      setIsWorking(false)
                      props.modalCallback(false)
                    })
                    .catch((e) => {
                      setIsWorking(false)
                    })
                  })
                  .catch((e) => {
                    setIsWorking(false)
                  })        
                }

               

              
              }}
             >
               SEND
             </button>
           </div>
   
           </div>
        ))}
       

      </div>
 

    </div>
  )
}

export default SendTeamModal