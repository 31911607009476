import React, { useState } from "react"

import { InView } from "react-intersection-observer";



import { Roadmap, RoadmapBg, RoadmapImg, Logo } from "../../assets"; 
import useTextAnimation from "../../hooks/use-text-animation";


// road map lists
type IRoadMap = {
  id: number;
  active: boolean;
  title: string;
  lists: {
    active: boolean;
    text: string;
  }[];
};
const road_map_lists: IRoadMap[] = [
  {
    id: 1,
    active: true,
    title: "1st Quarter Plans",
    lists: [
      { active: false, text: "Staking" },
      { active: false, text: "Opening of the entertainment center" },
      { active: false, text: "Revenue sales of the entertainment center"},
      
    ],
  },
  {
    id: 2,
    active: false,
    title: "2nd Quarter Plans",
    lists: [
      { active: false, text: "In-game development pduck version" },
      { active: false, text: "Entertainment center new version" },
      { active: false, text: "Gathering the community on the site" },      
    ],
  },
  {
    id: 3,
    active: false,
    title: "3rd Quarter Plans",
    lists: [
      { active: false, text: "Entertainment center new version" },
      { active: false, text: "Stake new version" },
      { active: false, text: "Top 100 player listing" },      
    ],
  },
  {
    id: 4,
    active: false,
    title: "4th Quarter Plans",
    lists: [
      { active: false, text: "In-game development" },
      { active: false, text: "Entertainment center new version" },
      
    ],
  },
  {
    id: 5,
    active: false,
    title: "General Plans",
    lists: [
      { active: false, text: "When $300,000 is reached, the amount in the pool will be converted to an on-chain token type and used." },
      { active: false, text: "When total value reaches $6,000,000 $DUCKY will be listed on at least 3 exchanges." },
      { active: false, text: "When total value reaches $60,000,000 $DUCKY will be listed on Binance." },
      
    ],
  },
];

const RoadMapArea = () => {
  const [isView, setIsView] = useState<boolean>(false);
  useTextAnimation(isView);

  const handleInViewChange = (inView: boolean) => {
    if (inView) {
      setIsView(true);
    }
  };


  return (
    <section
      className="roadMap__area roadMap-bg section-pt-150 section-pb-150"
      style={{ backgroundImage: `url(${RoadmapBg})` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="roadMap__inner">
              <div className="row">
                <div className="col-xl-5 col-lg-6">
                  <div className="roadMap__content">
                    <h2 className="title">a look into roadmaps quarters</h2>
                    <p>
                      With Quarter 1 ending we are planing to integrate the staking logic, opening of the entertainment center and begin to revenue sales of the entertainment center.
                    </p>
                 
                  </div>
                  <div className="roadMap__img">
                    <img
                      src={Logo}
                      className="tg-parallax"
                      data-scale="2"
                      data-orientation="down"
                      alt="roadMap__img"
                    />
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6">
                  <div className="roadMap__steps-wrap">
                    {road_map_lists.map((item) => (
                      <div
                        key={item.id}
                        className={`roadMap__steps-item ${item.active ? "active" : ""}`}
                      >
                        <h3 className="title">{item.title}</h3>
                        <InView
                          as="ul"
                          onChange={handleInViewChange} className="roadMap__list list-wrap">
                          {item.lists.map((l, i) => (
                            <li
                              key={i}
                              className={`${l.active ? "active" : ""} tg__animate-text style2`}
                            >
                              {l.text}
                            </li>
                          ))}
                        </InView>
                        <img
                          src={RoadmapImg}
                          alt="img"
                          className="roadMap__steps-img"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadMapArea;
