import React from 'react'
import './transaction-card.css'
import { useScreenSize } from '../../utils'
import { ArrowRightBold, TetherUsdtIcon } from '../../assets';

type Props = {
    from: string,
    to: string,
    type: string,
    given: string,
    received: string,
    date: string
}

function TransactionCard(props: Props) {
    const screenSize = useScreenSize();
    return (
        <>
            {
                screenSize.width > 1024 ?
                    <div id='transaction-card'>
                        <div id='transaction-card-from-to-container'>
                            <div id='from-container'>
                                <img src={TetherUsdtIcon} alt='tether'/>
                                <p>{props.from}</p>
                            </div>
                            <div>
                                <img src={ArrowRightBold} alt='arrow-right'/>
                            </div>
                            <div>
                                <p>{props.to}</p>
                            </div>
                        </div>
                        <div id='transaction-card-other-container'>
                            <div id='transaction-card-type'>
                                <p>{props.type}</p>
                            </div>
                            <div id='transaction-card-giv-recidate-container'>
                                <div>
                                    <p>{props.given}</p>
                                </div>
                                <div>
                                    <p>{props.received}</p>
                                </div>
                                <div>
                                    <p>{props.date}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div id='mobile-transaction-card'>
                        <div id='mobile-transaction-card-main-info'>
                            <div id='mobile-transaction-card-from-to-container'>
                                <div id='mobile-from-container'>
                                    <img src={TetherUsdtIcon} alt='tether'/>
                                    <p>{props.from}</p>
                                </div>
                                <div id='mobile-transaction-card-arrow'>
                                    <img src={ArrowRightBold} alt='arrow-right'/>
                                </div>
                                <p>{props.to}</p>
                            </div>
                            <p>{props.type}</p>
                        </div>
                        <div id='mobile-transaction-card-date-container'>
                            <p>{props.date}</p>
                        </div>
                        <div id='mobile-transaction-card-given-received-container'>
                            <div id='mobile-transaction-given-container'>
                                <p>Given: </p>
                                <span>{props.given}</span>
                            </div>
                            <div id='mobile-transaction-received-container'>
                                <p>Received: </p>
                                <span>{props.received}</span>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default TransactionCard