"use client";
import React, { useEffect } from "react";
import { animationCreate } from "../../../utils/utils";

import ContextProvider from "../../../context/AppContext";
import AnimateMouse from "../../../components/common/AnimatedMouse";
import BackToTopCom from "../../../components/common/BackToTopCom";

if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    animationCreate();
  }, []);
  return (
    <ContextProvider>
        <AnimateMouse />
        {children}
        <BackToTopCom />
    </ContextProvider>
  );
};

export default Wrapper;
