
import React, { useEffect, useState } from "react";

import { BrandLogo01, BrandLogo02, BrandLogo03, SliderBg, SliderImg01, SliderShape01, SliderShape02, SliderShape03, SliderShape04 } from "../../assets";






// brands
const brands = [BrandLogo01, BrandLogo02, BrandLogo03];
const HeroBanner = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const calculateTimeLeft = () => {
    const difference = +new Date("2024-05-10") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;



  return (
    <section
    className="slider__area slider__bg"
    style={{ backgroundImage: `url(${SliderBg})` }}
  >
    <div className="slider-activee">
      <div className="single-slider">
        <div className="container custom-container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="slider__content">
                <h6 className="sub-title wow fadeInUp" data-wow-delay=".2s">
                Play For Free
                </h6>
                <h2 className="title wow fadeInUp" data-wow-delay=".5s">
                  DuckyVucky
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".8s">
                  {days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? "Play Now" :  `Starts in ${days < 10 ? "0"+days : days}:${hours < 10 ? "0"+hours : hours}:${minutes < 10 ? "0"+minutes : minutes}:${seconds < 10 ? "0"+seconds : seconds}`}
                </p>
                <div
                  className="slider__btn wow fadeInUp"
                  data-wow-delay="1.2s"
                >
                  <a href={localStorage.getItem("token") ? "/panel" : "/login"} className="tg-btn-1">
                    <span>Play Now</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-5 col-lg-6">
            <img
                  src={SliderImg01}
                  alt="img"
                  style={{ height: "auto" }}                      
                />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="slider__shapes">
      <img src={SliderShape01} alt="shape" />
      <img src={SliderShape02} alt="shape" />
      <img src={SliderShape03} alt="shape" />
      <img src={SliderShape04} alt="shape" />
    </div>
    <div className="slider__brand-wrap">
      <div className="container custom-container">
        <ul className="slider__brand-list list-wrap">
          {brands.map((b, i) => (
            <li
              onMouseEnter={() => setHoveredIndex(i)}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{
                opacity:
                  hoveredIndex === null || hoveredIndex === i ? 1 : 0.3,
                transition: "opacity 0.3s",
              }}
              key={i}
            >
              <a href="#">
                <img src={b} alt="brand" />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
  );
};

export default HeroBanner;
