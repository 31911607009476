import React, { useState } from 'react'
import './settings.css'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import UserState from '../../../state/reducers/state-types/user'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { AuthService } from '../../../api'
import { toast } from 'react-toastify'

function Settings() {
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")

    const dispatch = useDispatch()
    const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
    const userState = useSelector((s: State) => s.user as UserState)
    

    const resetState = () => {
        setNewPassword("")
        setOldPassword("")
    }



  return (
    <>
    <div id='settings-header-row'>
        <span>Settings</span>
    </div>
    <section id='settings'>
        <div className='settings-col-wrapper'>
        <div className='settings-input-group'>
            <label>Old Password</label>
            <input 
                value={oldPassword}
                type='password'
                onChange={(e) => setOldPassword(e.currentTarget.value)}
            />
        </div>
        <div className='settings-input-group'>
            <label>New Password</label>
            <input 
                value={newPassword}
                type='password'
                onChange={(e) => setNewPassword(e.currentTarget.value)}
            />
        </div>
        </div>
      

        <button
            onClick={() => {
                setIsWorking(true)
                AuthService.changePassword(userState.me?.email!, oldPassword, newPassword)
                .then((r) => {
                    setIsWorking(false)
                    toast("Password Changed Successfully!")
                    resetState()
                })
                .catch((e) => {
                    setIsWorking(false)
                    resetState()
                })
                resetState()
            }}
        >
            RESET PASSWORD
        </button>
    </section>
    </>
    
  )
}

export default Settings