import React, { useEffect, useState } from 'react'
import './item-card.css'
import { Duck, Egg, Inventory, NFTCrate, Potion, PotionChest } from '../../../data'
import { ItemDuckFemale, ItemDuckLegendary, ItemDuckMale, ItemEgg, ItemNFTCrate, ItemPotionBlue, ItemPotionChest, ItemPotionGreen, ItemPotionRed, SwardIcon } from '../../../assets'
import { ItemService, MarketplaceService, TransactionService, UserService } from '../../../api'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../reusable/modal/Modal'
import SellPriceModal from '../sell-price-modal/SellPriceModal'
import { State } from '../../../state/reducers'
import WalletState from '../../../state/reducers/state-types/wallet'
import { toast } from 'react-toastify'
import moment from 'moment'
import GameState from '../../../state/reducers/state-types/game'
import UserState from '../../../state/reducers/state-types/user'
import ConfirmModal from '../../reusable/confirm-modal/ConfirmModal'

type Props = {
  inventory: Inventory
  open?: (inventoryId: string, itemType: string) => void
}
function ItemCard(props: Props) {
  const dispatch = useDispatch()
  const {setInventory, setTeams} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
  const [isSellPriceModalOpen, setIsSellPriceModalOpen] = useState(false)
  const [isRefundConfirmModalOpen, setIsRefundConfirmModalOpen] = useState(false)
  const [isLevelUpConfirmModalOpen, setIsLevelUpConfirmModalOpen] = useState(false)

  const walletState = useSelector((s: State) => s.wallet as WalletState)
  const userState = useSelector((s: State) => s.user as UserState)
  const gameState = useSelector((s: State) => s.game as GameState)
  const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
  const dashboardState = useSelector((s: State) => s.game as GameState)
  const [notSellableDucks, setNotSellableDucks] = useState<string[]>([])

  useEffect(() => {
    const duckIds: string[] = []
    gameState.dashboard.forEach((g) => {
      if(userState.me?.id === g.miner.id) {
        g.minerTeam.ducks.map((d) => d._id).forEach((id) => duckIds.push(id))
      }
    })
    setNotSellableDucks(duckIds)
  }, [])

  useEffect(() => {
    const duckIds: string[] = []
    gameState.dashboard.forEach((g) => {
      if(userState.me?.id === g.miner.id) {
        g.minerTeam.ducks.map((d) => d._id).forEach((id) => duckIds.push(id))
      }
    })
    setNotSellableDucks(duckIds)
  }, [gameState.dashboard])
  
  



  if(props.inventory.item.itemType === "egg") {    
    const item = props.inventory.item as Egg
    return (
      <div className='item-card-body'>
        <img src={ItemEgg} className='item-card-image' alt='egg'/>
        <div className='item-card-info'>
          <span>Duck Egg</span>
          <span>1x</span>
        </div>
        <div className='item-card-action'>
          <button onClick={() => {            
            if(moment(item.opensAt).diff(Date.now(), "days") <= 0) {
              
              props.open!(props.inventory.id, props.inventory.item.itemType)  
            }            
          }}>
            {moment(item.opensAt).diff(Date.now(), "days") <= 0 ? "OPEN" : `${moment(item.opensAt).diff(Date.now(), "days")} Days Remaining`}
          </button>
        </div>        
      </div>
    )
  }

  if(props.inventory.item.itemType === "nft-crate") {
    const item = props.inventory.item as NFTCrate
    return (
      <>
       <div className='item-card-body'>
        <img src={ItemNFTCrate} className='item-card-image' alt='nft-crate'/>
        <div className='item-card-info'>
          <span>{item.name}</span>
          <span>1x</span>
        </div>
        <div className='item-card-action'>
          <button
            onClick={() => {
              props.open!(props.inventory.id, props.inventory.item.itemType)
            }}
          >
            OPEN
          </button>
         
        </div>        
      </div>
          <Modal
        isOpen={isRefundConfirmModalOpen}
        overlayClickCallback={setIsRefundConfirmModalOpen}
      >
        <ConfirmModal 
        text='This operation is not revertable. Do you want to continue?' 
        callback={setIsRefundConfirmModalOpen} 
        actionCallback={() => {
          setIsWorking(true)
          TransactionService.refund(walletState.wallet!, props.inventory.id)
              .then((r) => {
                UserService.getInventory(0)
                .then((data) => {
                  if(data) {
                    setInventory(data)
                  }
                  setIsWorking(false)
                })
                .catch((e) => {
                  setIsWorking(false)
                })
              })
              .catch((e) => {
                setIsWorking(false)
              })
        }}
        />
      </Modal>
      </>
     
    )
  }

  if(props.inventory.item.itemType === "duck") {
    const item = props.inventory.item as Duck
    return (
      <>
      <div className='item-card-body'>
        <div
          className='item-card-duck-info'
        >
             <span className='item-card-duck-number'>
            #{item.number}
          </span>
          <img alt='duck' src={item.duckType === "male" ? ItemDuckMale : (item.duckType === "female" ? ItemDuckFemale : ItemDuckLegendary)} className='item-card-image'/>
          <div className='item-card-duck-info-row'>
            <span>Lv.{item.level}</span>
            <div className='item-card-duck-power'>  
              <img src={SwardIcon} alt='sword'/>
              <span>{item.power}</span>
            </div>
          </div>
        </div>
        
      
        <div className='item-card-action'>
          {
            item.sellable && !notSellableDucks.includes(item._id)
            ?
            <button
            onClick={() => {
              if(item.onSale) {
                setIsWorking(true)
                MarketplaceService.deleteMarketplace(item._id)
                .then((r) => {
                  UserService.getInventory(0)
                  .then((data) => {
                    if(data) {
                      setInventory(data)
                    }
                    
                    setIsWorking(false)
                  })
                  .catch((e) => {
                    setIsWorking(false)
                  })

                  UserService.getTeams(0)
                  .then((data) => {
                    setTeams(data)
                  })
                })
                .catch((e) => {
                  setIsWorking(false)
                })
              } else {
                setIsSellPriceModalOpen(true)
              }
              
            }}
            style={{backgroundColor: item.onSale ? "red" : undefined}}
          >
            {item.onSale ? "REMOVE" : "SELL"}
          </button>
          :
          null
          }
         
          <button
            onClick={() => {
             setIsLevelUpConfirmModalOpen(true)
            }}
          >
            LEVEL UP
          </button>
          
        </div>        
      </div>
      <Modal
        isOpen={isSellPriceModalOpen}
        overlayClickCallback={setIsSellPriceModalOpen}
      >
        <SellPriceModal item={item._id} setIsSellPriceModalOpen={setIsSellPriceModalOpen}/>
      </Modal>

      <Modal
        isOpen={isRefundConfirmModalOpen}
        overlayClickCallback={setIsRefundConfirmModalOpen}
      >
        <ConfirmModal 
        text='This operation is not revertable. Do you want to continue?' 
        callback={setIsRefundConfirmModalOpen} 
        actionCallback={() => {
          TransactionService.refund(walletState.wallet!, props.inventory.id)
              .then((r) => {
                UserService.getInventory(0)
                .then((data) => {
                  if(data) {
                    setInventory(data)
                  }
                  setIsWorking(false)
                })
                .catch((e) => {
                  setIsWorking(false)
                })
              })
              .catch((e) => {
                setIsWorking(false)
              })
        }}
        />
      </Modal>

      <Modal
        isOpen={isLevelUpConfirmModalOpen}
        overlayClickCallback={setIsLevelUpConfirmModalOpen}
      >
        <ConfirmModal 
        text={`${item.level >= 1 || item.level <= 50 ? "100" : (item.level >= 51 || item.level <= 89 ? "150" : "200")} $DUCKY To Level Up. Do You Want To Continue.`} 
        callback={setIsLevelUpConfirmModalOpen} 
        actionCallback={() => {
          setIsWorking(true)
          let allDucks: Duck[] = []

          for(const game of gameState.dashboard) {
            if(game.miner.id === userState.me?.id) {
              allDucks = [...allDucks ,...game.minerTeam.ducks]
            } else {
              allDucks = [...allDucks ,...game.looterTeam!.ducks]
            }
          }

          let allDuckIds = allDucks.map((d) => d._id)

          if(allDuckIds.includes(item._id)) {
            toast("Duck currently is in a game!")
            setIsWorking(false)
            return
          } 



          ItemService.levelUp(props.inventory.id)
          .then((r) => {
            UserService.getInventory(0)
            .then((data) => {
              if(data) {
                setInventory(data)
              }
              setIsWorking(false)

            })
          })
          .catch((e) => {
            setIsWorking(false)
          })

          setIsLevelUpConfirmModalOpen(false)
        }}
        />
      </Modal>
      </>
      
    )
  }

  if(props.inventory.item.itemType === "potion-chest") {
    const item = props.inventory.item as PotionChest
    return (
      <div className='item-card-body'>
        <img src={ItemPotionChest} className='item-card-image' alt='potion-chest'/>
        <div className='item-card-info'>
          <span>{item.name}</span>
          <span>{props.inventory.quantity}x</span>
        </div>
        <div className='item-card-action'>          
          <button
            onClick={() => {
              props.open!(props.inventory.id, props.inventory.item.itemType)
            }}
          >
            OPEN
          </button>          
        </div>        
      </div>
    )
  }
    

  return (
    <div className='item-card-body'>
    <img alt='potion' src={(props.inventory.item as Potion).subType === "red" ? ItemPotionRed : ((props.inventory.item as Potion).subType === "green" ? ItemPotionGreen : ItemPotionBlue)} className='item-card-image'/>
    <div className='item-card-info'>
      <span>{(props.inventory.item as Potion).name}</span>
      <span>{props.inventory.quantity}x</span>
    </div>
    <div className='item-card-action'>          
      <button>POWER: {(props.inventory.item as Potion).power}</button>          
    </div>        
  </div>
  )
}

export default ItemCard