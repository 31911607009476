import React from 'react'
import './dashboard-idle-card.css'
import { IdleDucks } from '../../../assets'
import { useScreenSize } from '../../../utils'

type Props = {
  info: string,
  header: string,
  infoP: string
}

function DashboardIdleCard(props: Props) {
  const screenSize = useScreenSize()

  if (screenSize.width < 1024) {
    return (
      <div className='mobile-idle-card'>
        <img src={IdleDucks} alt='idle-ducks'/>
        <div className='mobile-action-info-col'>
          <span>{props.header}</span>
          <p>{props.infoP}</p>
        </div>
      </div>
    )
  }

  return (

    <div className='desktop-idle-card'>
        <img src={IdleDucks} alt='idle-ducks'/>
        <span className='info-span'>{props.info}</span>
        <hr/>
        <div className='action-info-col'>
          <span>{props.header}</span>
          <p>{props.infoP}</p>
        </div>
    </div>
  )
}

export default DashboardIdleCard