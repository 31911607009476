import { Dispatch } from "redux"
import { Marketplace } from "../../data"
import { MarketplaceActions } from "../actions"
import { MarketplaceActionType } from "../action-types"


export const setMarketplace = (data: Marketplace[])  => {
    return (dispatch:  Dispatch<MarketplaceActions.SetMarketplaceAction>) => {
        dispatch({
            type: MarketplaceActionType.SET_MARKETPLACE,
            payload: data
        })
    }
}