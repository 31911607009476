export enum Panes {
    DASHBOARD = "DASHBOARD",      
    INVENTORY = "INVENTORY",
    MARKETPLACE = "MARKETPLACE",
    MANAGE_TEAMS = "MANAGE_TEAMS",
    EXPLORE = "EXPLORE",
    PAYMENT = "PAYMENT",
    BUYDUCKY = "BUYDUCKY",
    BREEDING = "BREEDING",
    SETTINGS = "SETTINGS",
    PROFILE = "PROFILE",
    TRANSACTIONS = "TRANSACTIONS"
}