import React from 'react'
import './profile.css'
import { useScreenSize } from '../../../utils'
import { ProfileCard, TransactionCard } from '../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import UserState from '../../../state/reducers/state-types/user'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { PaneEnums } from '../../../enums'


type Props = {
    isEmpty: boolean,
    forMobile: boolean
}

function Profile(props: Props) {
    const screenSize = useScreenSize();
    const dispatch = useDispatch()
    const {changePane} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
    const userState = useSelector((s: State) => s.user as UserState)
    return (
        <section id='profile'
            style={{
                flexDirection: props.isEmpty ? 'row' : 'column',
                justifyContent: props.isEmpty ? 'space-between' : 'normal',
                alignItems: 'center'

            }}
        >

            {
                screenSize.width > 1024 ?
                    <>
                        <div id='profile-container'>
                            <ProfileCard usdtBallance={userState.me!.usdtBalance} ducky={userState.me!.duckyBalance} referenceCode={userState.me?.referenceCode!}/>
                        </div>
                        <div id='last-ten-transaction-container'>
                            <div id='last-ten-transaction-header'>
                                <div>
                                    <p>Last 10 Transaction</p>
                                </div>
                                <div id='last-ten-see-all'
                                    onClick={() => changePane(PaneEnums.Panes.TRANSACTIONS)}
                                >
                                    <p>See All</p>
                                </div>
                            </div>
                            <div id='last-ten-transictions'>
                                
                                {userState.me?.transactions ? userState.me?.transactions.filter((t) => t.confirmed).slice(0, 10).map((t) => (
                                    <TransactionCard key={t.id} from={t.from} to={t.to} type={t.type.toUpperCase()} given={t.amount.toString()} received={(t.amount * t.rate).toString()} date={t.createdAt.toString()} />    
                                )) : null}
                                
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div id='mobile-profile-container'>
                            <ProfileCard  usdtBallance={userState.me?.usdtBalance!} ducky={userState.me?.duckyBalance!} referenceCode={userState.me?.referenceCode!}/>
                        </div>
                        <div id='mobile-last-ten-transaction-container'>
                            <div id='mobile-last-ten-transaction-header'>
                                <div>
                                    <p>Last 10 Transaction</p>
                                </div>
                                <div id='mobile-last-ten-see-all'>
                                    <p>See All</p>
                                </div>
                            </div>
                            <div id='mobile-last-ten-transictions'>
                            {userState.me?.transactions ? userState.me?.transactions.filter((t) => t.confirmed).slice(0, 10).map((t) => (
                                    <TransactionCard key={t.id} from={t.from} to={t.to} type={t.type.toUpperCase()} given={t.amount.toString()} received={(t.amount * t.rate).toString()} date={t.createdAt.toString()} />    
                                )) : null}
                            </div>
                        </div>
                    </>
            }

        </section>
    )
}

export default Profile