import React, { useEffect, useState } from "react";
import axios from "axios";

import NftItemBox from "./NftItemBox";
import nft_data from "../../static-data/nft-data";

const NftItemArea = () => {
  
  return (
    <section className="nft-item__area">
      <div className="container custom-container">
        <div className="row justify-content-center">
            {
                /*
                  <div key={item.id} className="col-xxl-4 col-xl-5 col-lg-6 col-md-9">
              <NftItemBox item={item} />
            </div>
                */
            }
      
        </div>
      </div>
    </section>
  );
};

export default NftItemArea;
