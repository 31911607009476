export const animationCreate = async () => {
  if (typeof window !== "undefined") {
    try {
      const module = await import("wowjs");
      const WOW = module.default;
      new WOW.WOW({ live: false }).init();
    } catch (error) {
      console.error("Failed to load wowjs:", error);
    }
  }
};