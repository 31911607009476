import { KONG_URL } from "../../../constants/constants";

const UserRoutes = {
    baseUrl : KONG_URL,
    user : "/user",
    me : "/me",
    inventory : "/inventory",
    teams : "/teams",
    buyTeamSlot : "/buy-team-slot"

}

export default UserRoutes