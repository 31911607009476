import React, { useState } from 'react'
import './explore.css'
import { IdleDucks, RefreshIcon } from '../../../assets'
import { ExploreCard, Modal, SendTeamModal } from '../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import UserState from '../../../state/reducers/state-types/user'
import { Team } from '../../../data'
import GameState from '../../../state/reducers/state-types/game'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { GameService } from '../../../api'
import { toast } from 'react-toastify'


function Explore() {

  const [isSendTeamModalOpen, setIsSendTeamModalOpen] = useState(false)
  const [gameType, setGameType] = useState("mining")
  const [selectedGame, setSelectedGame] = useState<string | undefined>(undefined)

  const [isHistory, setIsHistory] = useState(false)

  const userState = useSelector((s: State) => s.user as UserState)
  const gameState = useSelector((s: State) => s.game as GameState)

  const dispatch = useDispatch()
  const {setLootable, setHistory} = bindActionCreators(actionCreators.GameActionCreators, dispatch)
  const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

  const filterAvailableTeams = (): Team[] => {
    const availableTeams = userState.teams.filter((t) => t.canPlay)
    return availableTeams
  }




  return (
    <>
    
    <section id='explore'>
      <div className='explore-header-row'>
        <div
           style={{
            display: "flex",
            alignItems: "center",
            gap: 16,
            backgroundColor: "red !important"
          }}
        >
        <span className='explore-header'>{isHistory ? "History" : "Loot Zone"}</span>
        <button
        style={{
          opacity: 0.8,
          marginRight: 8,
          width: 28,
          height: 28,
          backgroundColor: "transparent"
        }}

        onClick={() => {

      


          setIsWorking(true)
          GameService.getLootable()
          .then((lootable) => {
            if(lootable) {
              setLootable(lootable)
            } else {
              setLootable([])
            }

            GameService.getHistory()
            .then((history) => {
              if(history) {
                setHistory(history)
              } else {
                setHistory([])
              }
              setIsWorking(false)
            })
            .catch((e) => {
              setIsWorking(false)
            })
          })
          .catch((e) => {
            setIsWorking(false)
          })
          
        }}
      >
        <img width={28} height={28} src={RefreshIcon}/>
      </button>
        </div>
       
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 16,
            backgroundColor: "red !important"
          }}
        >
        <button
          onClick={() => setIsHistory(!isHistory)}
        >
          {isHistory ? "LOOT ZONE" : "HISTORY"}
        </button>
        <button
          onClick={() => {
            const targetDate = new Date(Date.UTC(2024, 8, 6)); // September 6th, 2024, UTC
            const currentDate = new Date(); // Current date and time
            
            if (currentDate < targetDate) {
              toast("Game Will Start At 6'th of September")  
              return
            } 

            setGameType("mining")
            setIsSendTeamModalOpen(true)
          }}
        >GO MINING</button>
        </div>
        
      </div> 
      {
        isHistory ? (
          gameState.history.length === 0 ? 
          (
            <div className='empty-wrapper'>
            <img src={IdleDucks} alt='idle-ducks'/>
            <span>There is no games played yet.</span>
          </div>
          ) : (
            <div className='explore-grid-container'>
            {gameState.history.map((g, index) => (
              <ExploreCard key={g._id} isHistory={isHistory} setSelectedGame={setSelectedGame} setGameType={setGameType} game={g} index={index} setIsSendTeamModalOpen={setIsSendTeamModalOpen}/>
            ))}
                                    
         
          </div>
          )
        ) : (
          gameState.lootable.length === 0 ? 
          (
            <div className='empty-wrapper'>
            <img src={IdleDucks} alt='idle-ducks'/>
            <span>There is no data yet.</span>
          </div>
          ) : (
            <div className='explore-grid-container'>
            {gameState.lootable.map((g, index) => (
              <ExploreCard key={g._id} isHistory={isHistory} setSelectedGame={setSelectedGame} setGameType={setGameType} game={g} index={index} setIsSendTeamModalOpen={setIsSendTeamModalOpen}/>
            ))}
                                    
         
          </div>
          )
        )

       
      }
      

    
    </section>
    <Modal
      isOpen={isSendTeamModalOpen}
      overlayClickCallback={setIsSendTeamModalOpen}
    >
      <SendTeamModal gameId={selectedGame} user={userState.me!} modalCallback={setIsSendTeamModalOpen} gameType={gameType} teams={filterAvailableTeams()}/>
    </Modal>
    </>
  )
}

export default Explore