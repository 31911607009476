import { combineReducers } from "redux"
import uiReducer from "./ui"
import marketplaceReducer from "./marketplace"
import userReducer from "./user"
import gameReducer from "./game"
import walletReducer from "./wallet"
import vaultReducer from "./vault"



const reducers = combineReducers({    
    ui: uiReducer,    
    marketplace: marketplaceReducer,
    user: userReducer,
    game: gameReducer,
    wallet: walletReducer,
    vault: vaultReducer
})

export default reducers

export type State = ReturnType<typeof reducers>