import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './reset.css'
import { Provider } from 'react-redux';
import { store } from './state';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MetaMaskProvider } from '@metamask/sdk-react';
import { LoadingScreen } from './components';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MetaMaskProvider
       debug={false}
       sdkOptions={{
           dappMetadata: {
               name: "Duckyvucky",
               url: window.location.href,
           },
           // Other options
       }}
    >
    <Provider store={store}>
    <App />    
    <LoadingScreen/>
    </Provider>    
    </MetaMaskProvider>
    <ToastContainer/>
  </React.StrictMode>
);

