import { toast } from "react-toastify"
import { UserClient } from "../../client"
import UserRoutes from "./routes"


export const getMe = async () => {
    
    const response = await UserClient.get(`${UserRoutes.user}${UserRoutes.me}`)
    
    if (response.status !== 200 || response.data.status !== 200) {
        toast("Network Error Me!")
        return null
    }

    return response.data.data
}

export const getInventory = async (skip: number, filter?: string) => {
    
    const response = await UserClient.get(`${UserRoutes.user}${UserRoutes.inventory}?skip=${skip}&filter=${filter ?? "all"}`)

    if (response.status !== 200 || response.data.status !== 200) {
        toast("Network Error Inventory!")
        return null
    }

    return response.data.data

}

export const getTeams = async (skip: number) => {
    const response = await UserClient.get(`${UserRoutes.user}${UserRoutes.teams}?skip=${skip}`)

    if (response.status !== 200 || response.data.status !== 200) {
        toast("Network Error Teams!")
        return null
    }

    return response.data.data
}

export const createTeam = async (name: string, ducks: string[]) => {
    const response = await UserClient.post(`${UserRoutes.user}${UserRoutes.teams}`, {
        name,
        ducks,
        canPlay: true
    })

    if (response.status !== 200 || response.data.status !== 200) {
        toast("Network Error Teams!")
        return null
    }

    return response.data.data
}

export const deleteTeam = async (id: string) => {
    const response = await UserClient.delete(`${UserRoutes.user}${UserRoutes.teams}/${id}`)
    
    if (response.status !== 200 || response.data.status !== 200) {
        toast("Network Error While Deleting Team!")
        return null
    }

    return response.data.data

}

export const buyTeamSlot = async () => {
    const response = await UserClient.patch(`${UserRoutes.user}${UserRoutes.buyTeamSlot}`)

    if (response.status !== 200 || response.data.status !== 200) {
        toast("Error While Buying Team Slot!")
        return null
    }

    return response.data.data
}