import React from 'react'
import './payment-card.css'
type Props = {
    title:string,
    onClick: () => void
}

function PaymentButton (props:Props) {
  return (
    <div id='payment-btn'
      onClick={props.onClick}
    >
        {props.title}
    </div>
  )
}

export default PaymentButton