import React from 'react'
import './bottom-navbar.css'
import { DashboardIcon, ExportIconDark, InventoryIcon, ManageTeamsIcon, MarketplaceIcon } from '../../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { PaneEnums } from '../../../enums'
import UIState from '../../../state/reducers/state-types/ui'

function BottomNavbar() {
    const dispatch = useDispatch()
    const uiState = useSelector((s: State) => s.ui as UIState)
    const {changePane} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

  return (
    <nav id='bottom-navbar'>
        <button
            onClick={() => changePane(PaneEnums.Panes.DASHBOARD)}
            className={uiState.currentPane === PaneEnums.Panes.DASHBOARD ? 'bottom-navbar-selected' : ''}
        >
            <img src={DashboardIcon} alt='dashboard'/>
        </button>
        <button
            onClick={() => changePane(PaneEnums.Panes.INVENTORY)}
            className={uiState.currentPane === PaneEnums.Panes.INVENTORY ? 'bottom-navbar-selected' : ''}
        >
            <img src={InventoryIcon} alt='inventory'/>
        </button>
        <button 
            onClick={() => changePane(PaneEnums.Panes.EXPLORE)}
            className='circle-button'
        >
            <img src={ExportIconDark} alt='explore'/>
        </button>
        <button
            onClick={() => changePane(PaneEnums.Panes.MARKETPLACE)}
            className={uiState.currentPane === PaneEnums.Panes.MARKETPLACE ? 'bottom-navbar-selected' : ''}
        >
            <img src={MarketplaceIcon} alt='marketplace'/>
        </button>
        <button
            onClick={() => changePane(PaneEnums.Panes.MANAGE_TEAMS)}
            className={uiState.currentPane === PaneEnums.Panes.MANAGE_TEAMS ? 'bottom-navbar-selected' : ''}
        >
            <img src={ManageTeamsIcon} alt='manage-teams'/>
        </button>
    </nav>
  )
}

export default BottomNavbar