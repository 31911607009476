import React, { useEffect, useRef, useState } from 'react'
import './buy-ducky.css'
import { useScreenSize } from '../../../utils'
import { Swap } from '../../../data'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import UserState from '../../../state/reducers/state-types/user'
import VaultState from '../../../state/reducers/state-types/vault'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { TransactionService, UserService, VaultService } from '../../../api'
import WalletState from '../../../state/reducers/state-types/wallet'
import { toast } from 'react-toastify'




function BuyDucky() {
    
   const screenSize = useScreenSize()
   const vaultState = useSelector((s: State) => s.vault as VaultState)
   const userState = useSelector((s: State) => s.user as UserState)
   const walletState = useSelector((s: State) => s.wallet as WalletState)

   const dispatch = useDispatch()

   const {setVault} = bindActionCreators(actionCreators.VaultActionCreators, dispatch)
   const {setMe} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
   const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
   
   const [from, setFrom] = useState("USDT")
   const [to, setTo] = useState("DUCKY")
   const [fromAmount, setFromAmount] = useState(0)
   const [toAmount, setToAmount] = useState(0)

   

    return (
        <section
            id='buy-ducky'
            style={{                                                
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
           

           <div className='swap-wrapper'>
                    <div className='swap-item-col'>
                        <span>You Send</span>
                        <div className='swap-select-wrapper'>
                            <select
                                onChange={(e) => {
                                    if(e.currentTarget.value === "USDT") {
                                        setTo("DUCKY")      
                                        setFrom("USDT")                                  
                                    } else {
                                        setTo("USDT")                                    
                                        setFrom("DUCKY")                                      
                                    }
                                    setToAmount(fromAmount)
                                    setFromAmount(toAmount)
                                }}
                            >
                                <option value="USDT" selected={from === "USDT"}>USDT</option>      
                                <option value="DUCKY" selected={from === "DUCKY"}>$DUCKY</option>                                                            
                            </select>                            
                         </div>
                        <input 
                            style={{
                                backgroundColor: "transparent"
                            }}
                            value={fromAmount}
                                type='number' 
                                onChange={(e) => {
                                    
                                  let ratio = undefined
                                  if(!isNaN(parseFloat(e.currentTarget.value))) {   
                                    setFromAmount(parseFloat(e.currentTarget.value))
                                    
                                    if(userState.me?.swaps.length === 0) {
                                        ratio = (vaultState.vault!.coinSupply/vaultState.vault!.vaultValue)   
                                       
                                        if(from === "USDT") {                                            
                                            setToAmount(ratio * parseFloat(e.currentTarget.value))
                                        } else {                     
                                            setToAmount(parseFloat(e.currentTarget.value) / ratio)
                                        }
                                    } else {
                                        let sentUSDT = 0
                                        let receivedUSDT = 0
                                        let sentDUCKY = 0
                                        let receivedDUCKY = 0

                                        for(const swap of userState.me!.swaps) {
                                            if(swap.from === "USDT") {
                                                sentUSDT += swap.sent
                                                receivedDUCKY += swap.received
                                            } else {
                                                sentDUCKY += swap.sent
                                                receivedUSDT += swap.received
                                            }
                                        }

                                        ratio =  ((vaultState.vault!.coinSupply + (receivedDUCKY - sentDUCKY))/(vaultState.vault!.vaultValue - (sentUSDT - receivedUSDT) ))
                                                                              
                                        if(from === "USDT") {       
                                                                                 
                                            setToAmount(ratio * parseFloat(e.currentTarget.value))
                                        } else {                     
                                            setToAmount( parseFloat(e.currentTarget.value) / ratio)
                                        }
                                    }
                                        
                                      
                                  }
                                 
                                    
                               
                                  
                                    
                                }}
                                
                            />
                    </div>

                    <div className='swap-item-col'>
                        <span>You Receive</span>
                        <div className='swap-select-wrapper'>
                            <select
                                onChange={(e) => {
                                    if(e.currentTarget.value === "USDT") {
                                        setFrom("DUCKY")        
                                        setTo("USDT")                                
                                    } else {
                                        setFrom("USDT")                                        
                                        setTo("DUCKY")
                                    }
                                    setFromAmount(toAmount)
                                    setToAmount(fromAmount)
                                    
                                }}
                            >
                                <option value="DUCKY"  selected={to === "DUCKY"}>$DUCKY</option>
                                <option value="USDT" selected={to === "USDT"}>USDT</option>                                
                            </select>                            
                         </div>
                     
                        <input 
                             style={{
                                backgroundColor: "transparent"
                            }}
                            value={toAmount}
                                type='number' 
                                onChange={(e) => {
                                    let ratio = undefined
                                    if(!isNaN(parseFloat(e.currentTarget.value))) {   
                                      setToAmount(parseFloat(e.currentTarget.value))
                                      
                                      if(userState.me!.swaps.length === 0) {
                                          ratio = (vaultState.vault!.coinSupply/vaultState.vault!.vaultValue)   
                                         
                                          if(to === "USDT") {                                            
                                              setFromAmount(ratio * parseFloat(e.currentTarget.value))
                                          } else {                     
                                              setFromAmount(parseFloat(e.currentTarget.value) / ratio)
                                          }
                                      } else {
                                          let sentUSDT = 0
                                          let receivedUSDT = 0
                                          let sentDUCKY = 0
                                          let receivedDUCKY = 0
  
                                          for(const swap of userState.me!.swaps) {
                                              if(swap.from === "USDT") {
                                                  sentUSDT += swap.sent
                                                  receivedDUCKY += swap.received
                                              } else {
                                                  sentDUCKY += swap.sent
                                                  receivedUSDT += swap.received
                                              }
                                          }
  
                                          ratio =  ((vaultState.vault!.coinSupply + (receivedDUCKY - sentDUCKY))/(vaultState.vault!.vaultValue - (sentUSDT - receivedUSDT) ))
                                                                                
                                          if(to === "USDT") {       
                                                                                   
                                              setFromAmount(ratio * parseFloat(e.currentTarget.value))
                                          } else {                     
                                              setFromAmount( parseFloat(e.currentTarget.value) / ratio)
                                          }
                                      }
                                          
                                        
                                    }
                                 
                                 
                                }}
                                
                            />
                       
                    </div>

                    <button
                        onClick={() => {
                            if(!walletState.wallet) {
                                toast("Connect a wallet to swap!")
                                return
                            }
                            setIsWorking(true)
                            if(userState.me!.swaps.length <= 0) {
                                const ratio = (vaultState.vault!.coinSupply/vaultState.vault!.vaultValue)                                          
                                TransactionService.swap(fromAmount, from, to, ratio, walletState.wallet!)
                                .then((d) => {
                                    UserService.getMe()
                                    .then((me) => {
                                        setMe(me)
                                    })
                                    .catch((e) => {
                                        setIsWorking(false)
                                    })
                                    VaultService.getVaultByCoinName("ducky")
                                    .then((vault) => {
                                        setVault(vault)
                                        setIsWorking(false)
                                    })
                                    .catch((e) => {
                                        setIsWorking(false)
                                    })
                                })
                            } else {
                                if(!walletState.wallet) {
                                    toast("Connect a wallet to swap!")
                                    return
                                }
                                let sentUSDT = 0
                                let receivedUSDT = 0
                                let sentDUCKY = 0
                                let receivedDUCKY = 0

                                for(const swap of userState.me!.swaps) {
                                    if(swap.from === "USDT") {
                                        sentUSDT += swap.sent
                                        receivedDUCKY += swap.received
                                    } else {
                                        sentDUCKY += swap.sent
                                        receivedUSDT += swap.received
                                    }
                                }

                                const ratio =  ((vaultState.vault!.coinSupply + (receivedDUCKY - sentDUCKY))/(vaultState.vault!.vaultValue - (sentUSDT - receivedUSDT) ))
                                TransactionService.swap(fromAmount, from, to, ratio,walletState.wallet!)
                                .then((d) => {
                                    UserService.getMe()
                                    .then((me) => {
                                        setMe(me)
                                    })
                                    .catch((e) => {
                                        setIsWorking(false)
                                    })
                                    VaultService.getVaultByCoinName("ducky")
                                    .then((vault) => {
                                        setVault(vault)
                                        setIsWorking(false)
                                    })
                                    .catch((e) => {
                                        setIsWorking(false)
                                    })
                                })
                            }
                                                                           
                            
                        }}
                    >
                        SWAP
                    </button>
                </div>
            


        </section>
    )
}

export default BuyDucky