import { toast } from "react-toastify"
import { TransactionClient } from "../../client"
import TransactionRoutes from "./routes"


export const deposit = async (amount: number, walletAddress: string, transactionCode: undefined | string) => {    
    const response = await TransactionClient.post(
        TransactionRoutes.transaction,
        {
            from: "USDT",
            to: "USDT",
            type: "deposit",
            amount: amount,
            rate: 1,
            transactionCode,
            walletAddress
        }
    )
        
    if (response.status !== 200) {
        toast("Deposit Error!")        
    } else {
        toast(`${amount} USDT Deposited!`)
    }
}

export const withdraw = async (amount: number, walletAddress: string) => {
    const response = await TransactionClient.post(
        TransactionRoutes.transaction,
        {
            from: "USDT",
            to: "USDT",
            type: "withdraw",
            amount: amount,
            rate: 1,
            walletAddress,
            transactionCode: undefined
        }
    )

    if (response.status !== 200) {
        toast("Withdraw Error!")        
    } else {
        toast(`${amount} USDT Withdraw Request Sent!`)
    }
}

export const swap = async (amount: number, from: string, to: string, rate: number, walletAddress: string) => {
    const response = await TransactionClient.post(
        TransactionRoutes.transaction,
        {
            from: from,
            to: to,
            type: "swap",
            amount: amount,
            rate,
            walletAddress,
            transactionCode: undefined
        }
    )

    if (response.status !== 200) {
        toast("Swap Error!")        
    } else {
        toast(`Swap confirmed From ${from} To ${to} Amount: ${amount}!`)
    }
}

export const refund = async (walletAddress: string, inventoryId: string) => {
    const response = await TransactionClient.post(
        `${TransactionRoutes.transaction}${TransactionRoutes.refund}/${inventoryId}`,
        {
            walletAddress,
            totalAmount: 34,
            paidAmount: 3.4
        }
    )

    if (response.status !== 200 || response.data.status !== 200) {
        toast("Refund Error!")        
    } 
}