import { KONG_URL } from "../../../constants/constants"

const GameRoutes = {
    baseUrl : KONG_URL,
    game : "/game",
    dashboard : "/dashboard",
    history : "/history",
    lootable : "/lootable",
    loot : "/loot",
    boost : "/boost",
    end : "/end"
}

export default GameRoutes