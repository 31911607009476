import { WalletActionType } from "../action-types"
import WalletState from "./state-types/wallet"

const initalState: WalletState = {
    wallet: undefined,        
}


interface Action {
    type: string,
    payload: any
}


const reducer = (state: any = initalState, action: Action) => {
    switch(action.type) {
        case WalletActionType.SET_WALLET:            
            return {...state, wallet: action.payload}       
        default: 
            return state  
    }
}

export default reducer