import React, { useState } from 'react'
import './sell-price-modal.css'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import UserState from '../../../state/reducers/state-types/user'
import { toast } from 'react-toastify'
import { MarketplaceService, UserService } from '../../../api'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'

type Props = {
    item: string,
    setIsSellPriceModalOpen: (status: boolean) => void
}

function SellPriceModal(props: Props) {
    const [price, setPrice] = useState<number | undefined>(undefined)

    const dispatch = useDispatch()
    const {setInventory, setTeams} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
    const {setMarketplace} = bindActionCreators(actionCreators.MarketplaceActionCreators, dispatch)
    const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)   
    const userState = useSelector((s: State) => s.user as UserState)

  return (
    <div
        onClick={(e) => {
            e.stopPropagation()
        }}
        className='sell-price-body'
    >
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <div className='close-modal-row'>
                <button
                    onClick={() => props.setIsSellPriceModalOpen(false)}
                >
                    &#x2715;
                </button>
            </div>
            <div
                className='sell-price-input-group'
            >
                <label>PRICE</label>
                <input type='number' value={price} placeholder='Enter The Price' onChange={(e) => setPrice(parseFloat(e.currentTarget.value))}/>
            </div>
            <span
                style={{
                    fontSize: "1.8vh",
                    fontWeight: "600",
                    color: "white",
                    marginTop: "1.48vh"
                }}
            >
                Commision: {price ? (!isNaN(price) ? price * 0.04 : "-") : "-"} $DUCKY
            </span>
            <span
                  style={{
                    fontSize: "1.8vh",
                    fontWeight: "600",
                    color: "white",
                    marginTop: "1.48vh"
                }}
            >
                You will receive: {price ? (!isNaN(price) ? price - (price * 0.04) : "-") : "-"} $DUCKY
            </span>
        </div>
        

        <button
            onClick={() => {
                if(!price || isNaN(price)) {
                    toast("You Must Enter The Price!")
                    return
                }
                setIsWorking(true)

                MarketplaceService.sell(props.item, userState.me?.id!, price)
                .then((r) => {
                    UserService.getInventory(0)
                    .then((data) => {
                        if(data) {
                            setInventory(data)
                          }
                        setIsWorking(false)
                    })
                    .catch((e) => {
                        setIsWorking(false)
                    })

                    UserService.getTeams(0)
                    .then((data: any) => {
                        if(data) {
                            setTeams(data)
                        }
                    })

                    MarketplaceService.getMarketplace(0)
                    .then((data: any) => {
                        if(data) {
                            setMarketplace(data)
                        }
                        
                    })


                })
                .catch((e) => {
                    setIsWorking(false)
                })

                props.setIsSellPriceModalOpen(false)
                setPrice(undefined)                
            }}
        >
            SELL
        </button>
        
    </div>
  )
}

export default SellPriceModal