import { GameActionType } from "../action-types"
import GameState from "./state-types/game"

const initalState: GameState = {
    dashboard: [],
    lootable: [],
    history: []
}


interface Action {
    type: string,
    payload: any
}


const reducer = (state: any = initalState, action: Action) => {
    switch(action.type) {
        case GameActionType.SET_HISTORY:
            return {...state, history: action.payload}
        case GameActionType.SET_DASHBOARD:
            return {...state, dashboard: action.payload}
        case GameActionType.SET_LOOTABLE:
            return {...state, lootable: action.payload}
        default: 
            return state            
    }
}

export default reducer