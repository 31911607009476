import { GameActionType, VaultActionType } from "../action-types"
import GameState from "./state-types/game"
import VaultState from "./state-types/vault"

const initalState: VaultState = {
    vault: undefined,    
}


interface Action {
    type: string,
    payload: any
}


const reducer = (state: any = initalState, action: Action) => {
    switch(action.type) {
        case VaultActionType.SET_VAULT:
            return {...state, vault: action.payload}                    
        default: 
            return state            
    }
}

export default reducer