import React from 'react'
import './marketplace.css'
import { useSelector } from 'react-redux';
import { State } from '../../../state/reducers';
import MarketplaceState from '../../../state/reducers/state-types/marketplace';
import { MarketplaceCard, SelectFilter, SortSelect } from '../../../components';


function Marketplace() {
  
  const marketplaceState = useSelector((s: State) => s.marketplace as MarketplaceState)


  return (
    <>
    <div id='marketplace-header-row'>
      <span className='marketplace-header'>Marketplace</span>
      
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 36          
        }}
      >
        <SortSelect type='Level'/>
        <SortSelect type='Price'/>
        <SortSelect type='Date'/>
        <SelectFilter options={["All", "NFT Crate", "Duck", "Potion Chest"]} type='marketplace'/>
      </div>
      
    </div>
      <section
    id='marketplace'   
  >
    {marketplaceState.marketplace.map((m) => (
      <MarketplaceCard key={m.id} marketplace={m}/>
    ))}
  </section>
    </>
  

  )
}

export default Marketplace