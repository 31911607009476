import { Dispatch } from "redux"
import { Game } from "../../data"
import { GameActions } from "../actions"
import { GameActionType } from "../action-types"

export const setDashboard = (data: Game[])  => {
    return (dispatch:  Dispatch<GameActions.SetDashboardAction>) => {
        dispatch({
            type: GameActionType.SET_DASHBOARD,
            payload: data
        })
    }
}

export const setLootable = (data: Game[])  => {
    return (dispatch:  Dispatch<GameActions.SetLootableAction>) => {
        dispatch({
            type: GameActionType.SET_LOOTABLE,
            payload: data
        })
    }
}

export const setHistory = (data: Game[])  => {
    return (dispatch:  Dispatch<GameActions.SetHistoryAction>) => {
        dispatch({
            type: GameActionType.SET_HISTORY,
            payload: data
        })
    }
}