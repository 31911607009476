import React, { useState } from 'react'
import './select-filter.css'
import { ArrowDown } from '../../../assets'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { MarketplaceService, UserService } from '../../../api'


type Props = {
    options: string[],
    type: string
}

function SelectFilter(props: Props) {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState(props.options[0])
    const [options, setOptions] = useState(props.options.filter((o) => o !== "All"))

    const dispatch = useDispatch()
    const {setMarketplace} = bindActionCreators(actionCreators.MarketplaceActionCreators, dispatch)
    const {setInventory} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
    const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

    

    const setFilter = (filter: string) => {
        setIsWorking(true)

        if(filter !== "All") {
            const newOptions = ["All", ...options.filter((o) => o !== "All")]    
            setOptions(newOptions)
            setSelectedFilter(filter)
        } else {
            const newOptions = props.options.filter((o) => o !== "All")
            setOptions(newOptions)
            setSelectedFilter("All")
        }

        if(props.type === "marketplace") {
            MarketplaceService.getMarketplace(0, buildFilter(filter))
            .then((data: any) => {
                if(data) {
                    setMarketplace(data)
                }                
                setIsWorking(false)
            })
            .catch((e) => {
                setIsWorking(false)
            })
        } else {
            UserService.getInventory(0, buildFilter(filter))
            .then((data: any) => {
                if(data) {
                    setInventory(data)
                }                
                setIsWorking(false)
            })
            .catch((e) => {
                setIsWorking(false)
            })
        }
    }

    const buildFilter = (filter: string): string => {
        const splittedFilter = filter.split(" ")
        var buildedFilter: string = "all"
        if(splittedFilter.length > 1) {
            buildedFilter = splittedFilter.map((w) => w.toLowerCase()).join("-")
        } else {
            buildedFilter = filter.toLowerCase()
        }

        return buildedFilter
    }

  return (
    <button
        className='select-filter-button'
        style={{
            backgroundColor: isOpen ? "var(--secondary)" : "transparent"
        }}
        onClick={() => {
            setIsOpen(!isOpen)
        }}
    >
        {selectedFilter}
        <img src={ArrowDown} alt='chevron-down'/>      
        <div 
            style={{
                backgroundColor: isOpen ? "var(--secondary)" : "transparent",
                display: isOpen ? "flex" : "none",
                visibility: isOpen ? "visible" : "hidden"
            }}

            className='select-filter-carousel'
        >
            {
                options.map((o) => (
                    <button
                        onClick={() => setFilter(o)}
                    >
                        {o}
                    </button>
                ))
            }    
        </div>
    </button>
  )
}

export default SelectFilter