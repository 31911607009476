import { Dispatch } from "redux"
import { UIActionType } from "../action-types"
import { UIActions } from "../actions"


export const changePane = (data: string)  => {
    return (dispatch:  Dispatch<UIActions.ChangePaneAction>) => {
        dispatch({
            type: UIActionType.CHANGE_PANE,
            payload: data
        })
    }
}

export const setIsWorking = (data: boolean)  => {
    return (dispatch:  Dispatch<UIActions.SetIsWorkingAction>) => {
        dispatch({
            type: UIActionType.SET_IS_WORKING,
            payload: data
        })
    }
}

export const setIsSidebarOpen = (data: boolean)  => {
    return (dispatch:  Dispatch<UIActions.SetIsSidebarOpenAction>) => {
        dispatch({
            type: UIActionType.SET_IS_SIDEBAR_OPEN,
            payload: data
        })
    }
}



