import React from 'react';
import {  ConfirmAccount, HomePage,  LoginPage, Panel, RegisterPage,} from './pages';








import "./globals.scss";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ProtectedRoutes from './utils/ProtectedRoutes';



function App() {


return (
  <Router>
    <Routes>
      <Route element={<ProtectedRoutes/>}>
          <Route path='/panel' element={<Panel/>}/>          
          <Route element={<LoginPage/>} path='/login'/>
          <Route element={<RegisterPage/>} path='/register'/>
      </Route>      
      <Route element={<HomePage/>} path='/'/>
      <Route element={<ConfirmAccount/>} path='/confirm/:userId/:confirmationCode'/>
    </Routes>
  </Router>
)
}

export default App;
