import React from 'react'
import './mobile-sidebar.css'
import { BuyduckyIcon, HeartIcon, Logo, LogoutIcon, PaymentIcon, SettingsIcon } from '../../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import UIState from '../../../state/reducers/state-types/ui'
import { PaneEnums } from '../../../enums'
function MobileSidebar() {
    const dispatch = useDispatch()
    const uiState = useSelector((s: State) => s.ui as UIState)
    const {changePane, setIsSidebarOpen} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

  return (
    <div 
        className='mobile-sidebar-wrapper'
        style={{
            display: uiState.isSidebarOpen ? "flex" : "none",
            visibility:  uiState.isSidebarOpen ? "visible" : "hidden"
        }}

        onClick={() => {
            setIsSidebarOpen(false)
        }}
    >
        <nav className='mobile-sidebar'
            onClick={(e) => {
                e.stopPropagation()
            }}
        >           
            <div className='mobile-sidebar-section'>
                <img className='mobile-sidebar-logo' src={Logo} alt='Logo'/>
                <hr/>
                <button
                    onClick={() => {
                        changePane(PaneEnums.Panes.PAYMENT)
                        setIsSidebarOpen(false)
                    }}
                    className={uiState.currentPane === PaneEnums.Panes.PAYMENT ? 'mobile-sidebar-button-selected' : ''}
                >
                    <img src={PaymentIcon} alt='payment'/>
                    PAYMENT
                </button>
                <button
                      onClick={() => {
                        changePane(PaneEnums.Panes.BUYDUCKY)
                        setIsSidebarOpen(false)
                    }}
                    className={uiState.currentPane === PaneEnums.Panes.BUYDUCKY ? 'mobile-sidebar-button-selected' : ''}
                >
                    <img src={BuyduckyIcon} alt='buy-ducky'/>
                    BUYDUCKY
                </button>
                <button
                      onClick={() => {
                        changePane(PaneEnums.Panes.BREEDING)
                        setIsSidebarOpen(false)
                    }}
                    className={uiState.currentPane === PaneEnums.Panes.BREEDING ? 'mobile-sidebar-button-selected' : ''}
                >
                    <img src={HeartIcon} alt='heart'/>
                    BREEDING
                </button>
                <button 
                    onClick={() => {
                        changePane(PaneEnums.Panes.SETTINGS)
                        setIsSidebarOpen(false)
                    }}
                    className={uiState.currentPane === PaneEnums.Panes.SETTINGS ? 'mobile-sidebar-button-selected' : ''}
                >
                    <img src={SettingsIcon} alt='settings'/>
                    SETTINGS
                </button>
            </div>

            <button 
                className='mobile-sidebar-logout'
                onClick={() => {
                    localStorage.removeItem("token")
                    window.location.reload()
                }}
            >
                    <img src={LogoutIcon} alt='logout'/>
                    LOGOUT
                </button>
          
        </nav>
    </div>
  )
}

export default MobileSidebar