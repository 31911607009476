import React from 'react'
import './marketplace-card.css'
import { Duck, Marketplace, NFTCrate, PotionChest } from '../../../data'
import { ItemDuckFemale, ItemDuckLegendary, ItemDuckMale, ItemNFTCrate, ItemPotionChest, SwardIcon } from '../../../assets'
import { MarketplaceService, UserService } from '../../../api'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'
import { State } from '../../../state/reducers'
import UserState from '../../../state/reducers/state-types/user'


type Props = {
    marketplace: Marketplace
}

function MarketplaceCard(props: Props) {

  const item: PotionChest | NFTCrate | undefined = props.marketplace.item as PotionChest | NFTCrate | undefined
  const duck: Duck | undefined = item?.itemType === 'duck' ? props.marketplace.item as Duck : undefined

  const dispatch = useDispatch()
  const {setInventory, setMe} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
  const {setMarketplace} = bindActionCreators(actionCreators.MarketplaceActionCreators, dispatch)
  const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

  const userState = useSelector((s: State) => s.user as UserState)
  

  return (
    <div className='marketplace-card'>
      <div className='image-and-item-info-col'>
        {
          item?.itemType === 'duck'
          ?
          (
            <div className='marketplace-duck-number'
             
            >
            #{duck?.number}
          </div>
          )
          :null
        }
    
        <img alt='marketplace' src={  item?.itemType === 'nft-crate' ? ItemNFTCrate : item?.itemType === 'potion-chest' ? ItemPotionChest : item?.itemType === 'duck' ? (duck?.duckType === 'male' ? ItemDuckMale : duck?.duckType === 'legendary' ? ItemDuckLegendary : ItemDuckFemale) : undefined }/>
        {
          item?.itemType === 'duck' 
          ?  
          <div className='marketplace-duck-info'>
            <span>Lv.{duck?.level}</span>
            <div className='marketplace-duck-power'>
              <img alt='sword' src={SwardIcon}/>
              <span>{duck?.power}</span>
            </div>           
          </div>
          : 
          <span>{(item?.name)}</span>
        }
        
      </div>
      {
        item?.itemType === 'duck'
        ?
        null
        :
        (
          <div className='remaining-and-quantity-col'>
          <span>{props.marketplace.remaining ? "REMAINING" : "QUANTITY"}</span>
          <span>{props.marketplace.remaining ? props.marketplace.remaining : props.marketplace.quantity}</span>
      </div>
        )
      
      }
    
      <div className='marketplace-action-col'>
      <span>{props.marketplace.price} $DUCKY</span>
        
        <button
        style={{
          backgroundColor: userState.me?.id === props.marketplace.seller ? "red" : undefined
        }}
          onClick={() => {
            setIsWorking(true)
            if(userState.me?.id !== props.marketplace.seller) {
              MarketplaceService.buy(item?.itemType!, props.marketplace.id)
              .then((r) => {
                MarketplaceService.getMarketplace(0)
                .then((marketplace) => {                
                  UserService.getInventory(0)
                  .then((inventory) => {
                    if(inventory) {
                      setInventory(inventory)
                    }
                    setMarketplace(marketplace)
                    setIsWorking(false )
                  })
                  .catch((e) => {
                    setIsWorking(false)
                  })

                  UserService.getMe()
                  .then((me) => {
                    setMe(me)
                  })
                })
                .catch((e) => {
                  setIsWorking(false)
                })
              })
              .catch((e) => {
                setIsWorking(false)
              })
            } else {
              MarketplaceService.deleteMarketplace(item?._id!)
              .then((r) => {
                MarketplaceService.getMarketplace(0)
                .then((marketplace) => {                
                  UserService.getInventory(0)
                  .then((inventory) => {
                    if(inventory) {
                      setInventory(inventory)
                    }
                    
                    setMarketplace(marketplace)
                    setIsWorking(false )
                  })
                  .catch((e) => {
                    setIsWorking(false)
                  })
                })
                .catch((e) => {
                  setIsWorking(false)
                })
              })
              .catch((e) => {
                setIsWorking(false)
              })              
            }
           


          }}
        >
          {userState.me?.id === props.marketplace.seller ? "REMOVE" : "BUY"}
        </button>
      </div>
    </div>
  )
}

export default MarketplaceCard