import React, { useEffect, useState } from 'react'
import './manage-teams.css'
import ManageTeamsCard from '../../../components/manage-teams/ManageTeamsCard'
import { IdleDucks } from '../../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../state/reducers'
import UserState from '../../../state/reducers/state-types/user'
import { BuyTeamSlot, CreateEditTeamModal, Modal, SelectDuckModal,} from '../../../components'
import { Duck } from '../../../data'
import { UserService } from '../../../api'
import { toast } from 'react-toastify'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'




function ManageTeams() {    
    const [isCreateEditTeamModalOpen, setIsCreateEditTeamModalOpen] = useState(false)
    const [isSelectDuckModalOpen, setIsSelectDuckModalOpen] = useState(false)
    const [isBuyTeamSlotModalOpen, setIsBuyTeamSlotModalOpen] = useState(false)
    const [availableDucks, setAvailableDucks] = useState<Duck[]>([])
    const [selectedDucks, setSelectedDucks] = useState<(Duck | undefined)[]>([undefined, undefined, undefined])
    const [currentDuckIndex, setCurrentDuckIndex] = useState(0)
    const [teamName, setTeamName] = useState<string>("")

    const dispatch = useDispatch()
    const {setTeams} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
    const userState = useSelector((s: State) => s.user as UserState)
    const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

    


    const selectUnUsedDucks = () => {
     
        var ducksInInventory =  userState.inventory.filter((i) => i.item.itemType === "duck")
        const ducksInTeams = userState.teams.map((t) => t.ducks)
        for(const duckList of ducksInTeams) {
                for(const duck of duckList) {
                    ducksInInventory = ducksInInventory.filter((i) => i.item._id !== duck._id)                    
                }
        }        
        ducksInInventory = ducksInInventory.filter((d) => !selectedDucks.map((sd) => sd?._id).includes(d.item._id))               
        setAvailableDucks(ducksInInventory.map((d) => d.item as Duck))                  
    }

    const resetState = () => {
        setSelectedDucks([undefined, undefined, undefined])
        selectUnUsedDucks()
        setTeamName("")
    }


    const create = () => {

        if(selectedDucks.filter((d) => d !== undefined).length !== 3) {
            toast("Minimum 3 Ducks Required For Team Creation!")
            return
        }

        if(teamName.length === 0) {
            toast("Team Name Required For Team Creation!")
            return
        }
        setIsWorking(true)
        UserService.createTeam(teamName, selectedDucks.map((d) => d!._id))
        .then((r) => {
            UserService.getTeams(0)
            .then((data) => {
                if(data) {
                    setTeams(data)
                }
                
                setIsWorking(false)
            
                selectUnUsedDucks()
            })
            .catch((e) => {
                setIsWorking(false)
            })
        })
        .catch((e) => {
            setIsWorking(false)
        })
        setIsCreateEditTeamModalOpen(false)
        resetState()

    }

    useEffect(() => {
        selectUnUsedDucks()
    } ,[])

  
   

    useEffect(() => {
        selectUnUsedDucks()
        if(!isCreateEditTeamModalOpen) {
            resetState()
        }
    }, [isCreateEditTeamModalOpen, isSelectDuckModalOpen])



    
   

    return (
        <>
        <div id='manage-teams-action'> 
            <span id='manage-teams-header'>Manage Teams ({userState.teams.length}/{userState.me?.teamCountClaim})</span>
            <button
                onClick={() => {
                    if(userState.teams.length >= userState.me?.teamCountClaim!) {
                        setIsBuyTeamSlotModalOpen(true)
                    } else {
                        setIsCreateEditTeamModalOpen(true)
                    }
                    
                }}
            > {userState.teams.length >= userState.me?.teamCountClaim! ? "BUY TEAM SLOT" : "+ CREATE TEAM"}</button>
        </div>
        {
            userState.teams.length === 0 ? (
                <div className='empty-wrapper'>
                <img src={IdleDucks} alt='idle-ducks'/>
                <span>There is no teams yet.</span>
              </div>
            ) : (
                <section id='manage-teams'>
                {userState.teams.map((t) => (
                    <ManageTeamsCard key={t._id} team={t}/>
                ))}
             
            </section>
            )
        }
        
       
        <Modal
            isOpen={isCreateEditTeamModalOpen}
            overlayClickCallback={setIsCreateEditTeamModalOpen}
        >
            <CreateEditTeamModal setIsCreateEditTeamModalOpen={setIsCreateEditTeamModalOpen} setTeamName={setTeamName} create={create} setCurrentDuckIndex={setCurrentDuckIndex} setIsSelectDuckModalOpen={setIsSelectDuckModalOpen} selectedDucks={selectedDucks}/>
        </Modal>

        <Modal
            isOpen={isSelectDuckModalOpen}
            overlayClickCallback={setIsSelectDuckModalOpen}
        >
            <SelectDuckModal setSelectedDucks={setSelectedDucks} currentDuckIndex={currentDuckIndex} selectedDucks={selectedDucks}  availableDucks={availableDucks} setIsSelectDuckModalOpen={setIsSelectDuckModalOpen}/>
        </Modal>

        <Modal
            isOpen={isBuyTeamSlotModalOpen}
            overlayClickCallback={setIsBuyTeamSlotModalOpen}
        >
            <BuyTeamSlot setIsBuyTeamSlotModalOpen={setIsBuyTeamSlotModalOpen}/>
        </Modal>

    
        

        
        </>
    )
}

export default ManageTeams