

import Wrapper from "../home/wrapper/Wrapper";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";


import { BreadCrumbBG01, BreadCrumbIMG02 } from "../../assets";
import { useState } from "react";
import { AuthService } from "../../api";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";


export default function LoginPage() {
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  const dispatch = useDispatch()
  const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)

  const handleLogin = () => {
    if(email.length === 0) {
      toast("Email Cannot Be Empty!")
      return
    }

    if(password.length === 0) {
      toast("Password Cannot Be Empty!")
      return
    }
    
    setIsWorking(true)
    AuthService.login(email, password)
    .then((token) => {
      if(token) {
        const decodedJWT =  (jwtDecode(token) as any)
        if(!decodedJWT.emailConfirmed) {
          toast("Confirm Your Email!")
          setIsWorking(false)          
          return
        }
        localStorage.setItem("token", token)                
        setEmail("")
        setPassword("")
        window.location.reload()
      }        
      setIsWorking(false)      
    })
    .catch((e) => {
      setIsWorking(false)
    })
  }

  return (
    <Wrapper>
      <Header />
      <main className="main--area">
        <section
          className="breadcrumb-area"
          style={{ backgroundImage: `url(${BreadCrumbBG01})` }}
        >
          <div className="container">
            <div className="breadcrumb__wrapper">
              <div className="row">
                <div className="col-xl-6 col-lg-7">
                  <div className="breadcrumb__content">
                    <h2 className="title mb-5">Login</h2>
                    <nav aria-label="breadcrumb">
                     
                      <form action="#" className="footer-newsletter-form">
                        <input
                          type="email"
                          placeholder="E-Mail"                          
                          className={`mb-3`}           
                          onChange={(e) => setEmail(e.currentTarget.value)}               
                          value={email}
                        />
                        <input
                          type="password"
                          placeholder="Password"                          
                          className={``}     
                          onChange={(e) => setPassword(e.currentTarget.value)}                     
                          value={password}
                        />
                      </form>
                      <div className="flex-column d-flex mt-6">
                        <button 
                          className="btn" 
                          onClick={() => handleLogin()}
                        >
                          Login
                        </button>
                        <p className="link mt-5 mb-2">Or</p>
                        <a href="/register" className="btn">
                          Register
                        </a>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 position-relative d-none d-lg-block">
                  <div className="breadcrumb__img">
                    <img
                      src={BreadCrumbIMG02}
                      alt="img"
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </Wrapper>
  );
}
