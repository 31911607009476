import React, { Suspense, useState } from "react";
import menu_data from "../../static-data/menu-data";
import useSticky from "../../hooks/use-sticky";
import SearchPopup from "./SearchPopup";
import OffCanvas from "./OffCanvas";
import MobileOffCanvas from "./MobileOffCanvas";
import SvgIconCom from "./SvgIconAnim";
import { Shape02 } from "../../assets";
import { Logo } from "../../assets";


const Header = ({ style_2 = false }: { style_2?: boolean }) => {
    
  const { sticky, isStickyVisible } = useSticky();
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState<boolean>(false);
  const [openMobileOffCanvas, setOpenMobileOffCanvas] =
    useState<boolean>(false);
  // handle open offcanvas
  return (
    <Suspense fallback={<div>Yükleniyor</div>}>
      <header>
        <div
          id="sticky-header"
          className={`tg-header__area transparent-header ${
            sticky ? "tg-sticky-menu" : ""
          } ${isStickyVisible ? "sticky-menu__show" : ""}`}
        >
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="tgmenu__wrap">
                  <nav className="tgmenu__nav">
                    <div className="logo">
                    <img
                          src={Logo}
                          alt="Logo"
                          style={{ height: "auto" }}
                        />
                    </div>
                    <div className="tgmenu__navbar-wrap tgmenu__main-menu d-none d-xl-flex">
                      <ul className="navigation">
                        {menu_data.map((menu) =>
                          menu.sub_menu ? (
                            <li
                              key={menu.id}
                              className={`menu-item ${
                                menu.sub_menu &&
                                menu.sub_menu.some(
                                  (sub) => "/" === sub.link
                                )
                                  ? "menu-item-has-children active"
                                  : ""
                              }`}
                            >
                              <a href="#">{menu.title}</a>
                              <ul className="sub-menu">
                                {menu.sub_menu.map((sub, i) => (
                                  <li
                                    key={i}
                                    className={
                                      "/" === sub.link ? "active" : ""
                                    }
                                  >
                                    <a href={sub.link}>{sub.title}</a>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ) : (
                            <li
                              key={menu.id}
                              className={"/" === menu.link ? "active" : ""}
                            >
                              <a href={menu.link}>{menu.title}</a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <div className="tgmenu__action d-none d-md-block">
                      <ul className="list-wrap">
                        <li className="header-btn">
                          <a
                            href={localStorage.getItem("token") ? "/panel" : "/login"}
                            className={`${
                              style_2 ? "tg-btn-3 tg-svg" : "tg-border-btn"
                            }`}
                          >
                            {style_2 && <SvgIconCom icon={Shape02} id="svg-2" />}
                            <i className="flaticon-edit"></i>
                            {localStorage.getItem("token") ? "Dashboard" : "Sign In"}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- header-search --> */}
        <SearchPopup
          setIsSearchOpen={setIsSearchOpen}
          isSearchOpen={isSearchOpen}
        />
        {/* <!-- header-search-end --> */}

        {/* off canvas start */}
        <OffCanvas
          isOffCanvasOpen={isOffCanvasOpen}
          setIsOffCanvasOpen={setIsOffCanvasOpen}
        />
        {/* off canvas end */}

        {/*mobile off canvas start */}
        <MobileOffCanvas
          openMobileOffCanvas={openMobileOffCanvas}
          setOpenMobileOffCanvas={setOpenMobileOffCanvas}
        />
        {/*mobile off canvas end */}
      </header>
    </Suspense>
  );
};

export default Header;
