import React from 'react'
import './use-potion-modal.css'
import { ItemPotionBlue, ItemPotionGreen, ItemPotionRed } from '../../../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../../state/reducers'
import UserState from '../../../../state/reducers/state-types/user'
import { Potion } from '../../../../data'
import { GameService, UserService } from '../../../../api'
import { toast } from 'react-toastify'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../state'

type Props = {
  selectedGameId?: string,
  setSelectedGameId: (data: string | undefined) => void,
  setIsUsePotionModalOpen: (status: boolean) => void
}

function UsePotionModal(props: Props) {

  const dispatch = useDispatch()

  const {setIsWorking} = bindActionCreators(actionCreators.UIActionCreators, dispatch)
  const {setInventory} = bindActionCreators(actionCreators.UserActionCreators, dispatch)
  const {setDashboard} = bindActionCreators(actionCreators.GameActionCreators, dispatch)

  const userState = useSelector((s: State) => s.user as UserState)

  

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
      className='use-potion-body'
    >
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <div className='close-modal-row'>
                <button
                  style={{
                    color: "white",
                    border: "none",
                    fontSize: 32
                  }}
                  onClick={() => {
                    props.setIsUsePotionModalOpen(false)
                  }}
                >
                  &#x2715;
                </button>
            </div>
        </div>

        <span className='use-potion-header'>POTIONS</span>

        <div className='use-potion-wrapper'> 
        {userState.inventory.filter((i) => i.item.itemType === "potion").map((i) => {
          const item = i.item as Potion
          return (
            <div className='use-potion-card'
              key={i.id}
            >
              <div className='use-potion-card-potion'>
                <img alt='potion' src={item.subType === "red" ? ItemPotionRed : (item.subType === "green" ? ItemPotionGreen : ItemPotionBlue)}/>
                <span>{i.quantity}x</span>
              </div>
            
            <div className='use-potion-card-action'>
              <span>Power:{item.power}</span>
              <button
                onClick={() => {
                  if(!props.selectedGameId) {
                    toast("Select A Game To Use Potion!")
                    return
                  }
                  setIsWorking(true)
                  GameService.boost(props.selectedGameId!, i.id)
                  .then((r) => {
                    UserService.getInventory(0)
                    .then((data: any) => {
                      if(data) {
                        setInventory(data)
                      }
                      
                    })
                    .catch((e) => {
                      setIsWorking(false)
                    })

                    GameService.getDashboard()
                    .then((data: any) => {
                      setDashboard(data)
                      setIsWorking(false)
                    })
                    .catch((e) => {
                      setIsWorking(false)
                    })
                    
                  })
                  .catch((e) => {
                    setIsWorking(false)
                  })
                  props.setSelectedGameId(undefined)
                  props.setIsUsePotionModalOpen(false)
                }}
              >
                USE
              </button>
            </div>
          </div>           
          )
        })}
         
        </div>

    </div>
  )
}

export default UsePotionModal